@keyframes pulse_animation {
  0% {
    transform: scale(1); }
  30% {
    transform: scale(1); }
  40% {
    transform: scale(1.15); }
  50% {
    transform: scale(1); }
  60% {
    transform: scale(1); }
  70% {
    transform: scale(1.05); }
  80% {
    transform: scale(1); }
  100% {
    transform: scale(1); } }

.pulse {
  animation-name: pulse_animation;
  animation-duration: 2000ms;
  transform-origin: 70% 70%;
  animation-iteration-count: infinite;
  animation-timing-function: linear; }

.formulario {
  color: #5a5a5a; }
  .formulario h1 {
    font-family: Vollkorn, serif;
    text-transform: uppercase;
    font-size: 1.8rem; }

.modal {
  color: #5a5a5a; }
  .modal .modal-header h4 {
    font-size: 1.5rem; }
  .modal .modal-header .close {
    padding: 0.25rem; }
  .modal .libro .titulo {
    font-size: 1.1rem; }
  .modal ul {
    list-style: none; }

.form-control, .form-control:hover, .form-control:focus, .form-control:active {
  color: #5a5a5a;
  font-family: Lato, sans-serif; }

input[type=text]::-webkit-input-placeholder, input[type=search]::-webkit-input-placeholder {
  color: #912823; }

a {
  color: #912823; }
  a:hover, a:focus, a:active {
    color: #5a5a5a; }

ul {
  margin: 0;
  padding: 0; }

.btn {
  font-family: Lato, sans-serif;
  border-radius: 0px;
  transition: 0.5s all ease-in; }

.btn-outline-primary {
  border: 1px solid #912823;
  color: #912823; }
  .btn-outline-primary:hover, .btn-outline-primary:focus, .btn-outline-primary:active {
    background-color: #912823;
    border: 1px solid #912823; }

.btn-primary {
  border: 1px solid #912823;
  color: #ffffff;
  background-color: #912823; }
  .btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary .focus, .btn-primary .active {
    background-color: #912823;
    border: 1px solid #912823;
    opacity: 0.9; }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #912823;
    border: 1px solid #912823; }

.btn-success {
  border-color: #fff; }
  .btn-success:hover {
    color: inherit;
    background-color: inherit;
    border-color: inherit; }

.breadcrumb {
  background: transparent;
  padding: 0;
  margin: 0;
  padding: 0px 0.5rem; }
  .breadcrumb li + li:before {
    color: #5a5a5a;
    content: " > ";
    padding: 0 5px;
    font-size: 0.95rem; }
  .breadcrumb li a {
    color: #5a5a5a;
    font-family: Lato, sans-serif;
    font-weight: 400;
    font-size: 13px; }

.responsive-tabs-container .accordion-link {
  border-radius: 0px;
  padding: 0px;
  border: none;
  font-family: Vollkorn, serif;
  font-size: 1.3rem;
  text-transform: uppercase;
  margin-top: 0.75rem; }

.cont-central {
  margin-top: 20px; }

header {
  background: #FFFFFF;
  box-shadow: 1px 1px 1px #912823;
  font-size: 1.2rem; }
  header .navbar-toggler {
    color: #912823; }
    @media (min-width: 992px) {
      header .navbar-toggler {
        font-size: 26px; } }
  header .navbar-default .header .colLogo {
    z-index: 90; }
    @media (min-width: 576px) {
      header .navbar-default .header .colLogo a.navbar-brand img {
        width: 145px; } }
    @media (min-width: 768px) {
      header .navbar-default .header .colLogo a.navbar-brand img {
        width: 210px; } }
    @media (min-width: 576px) {
      header .navbar-default .header .colLogo a.navbar-brand img {
        width: 295px; } }
  header .navbar-default ul.contenedorMiCuenta li a {
    font-size: 12px;
    font-weight: 600;
    padding: 6px;
    display: table-cell;
    margin-left: 11px;
    float: left;
    color: #912823; }
    header .navbar-default ul.contenedorMiCuenta li a:hover {
      color: #5a5a5a; }
    @media (min-width: 992px) {
      header .navbar-default ul.contenedorMiCuenta li a {
        font-size: 14px; } }
  header .navbar-default ul.contenedorMiCuenta .registracionTablet {
    float: left; }
  header .navbar-default ul li a {
    font-family: Vollkorn, serif;
    font-size: 18px;
    padding-left: .75rem;
    padding-right: .75rem;
    padding-top: 0;
    color: #912823;
    text-transform: uppercase; }
    header .navbar-default ul li a a:not([href]):not([tabindex]) {
      color: #912823; }
    header .navbar-default ul li a a:not([href]):not([tabindex]):hover {
      color: #5a5a5a;
      cursor: pointer; }
    header .navbar-default ul li a a:hover {
      color: #5a5a5a;
      text-decoration: none; }
  header .navbar-default ul li ul li a {
    font-family: Vollkorn, serif;
    font-size: 16px;
    color: #5a5a5a; }
  header .navbar-default ul.contenedorMiCuenta li a span.badge {
    margin-left: 0;
    padding: 0; }
  header .navbar-default ul.contenedorMiCuenta li a span.cantidad {
    margin: 0;
    padding: 0;
    text-align: center;
    font-size: 13px;
    width: auto;
    left: 2px; }
  @media (min-width: 992px) {
    header .buscador .input-group .form-control {
      border-radius: 0px;
      border-top: none;
      border-bottom: 1px solid #912823;
      border-left: none;
      padding-left: 0px;
      border-right: 0px;
      height: calc(2.25rem + 6px);
      font-size: 18px; }
    header .buscador .input-group .input-group-append .btn {
      border-bottom: 1px solid #912823;
      border-radius: 0px;
      background-color: transparent;
      font-size: 22px;
      padding: 0px 4px;
      color: #912823; } }
  header .carrito {
    position: relative; }
    @media (min-width: 992px) {
      header .carrito.cartEmpty {
        color: var(--secondary-color); }
        header .carrito.cartEmpty .badge {
          display: none; } }
    header .carrito .badge {
      position: absolute;
      top: 2px;
      right: 0px;
      font-size: .9rem;
      display: none; }
  header .menuprincipal li.show > .dropdown-menu {
    background: #fff;
    padding: 25px 15px;
    position: absolute !important;
    width: 100%;
    left: 0;
    display: grid;
    grid: repeat(8, 1fr)/auto-flow;
    -webkit-grid: repeat(8, 1fr)/auto-flow; }
  header .slinky-menu {
    margin-left: -15px;
    margin-right: -15px;
    overflow: scroll;
    height: 100vw !important; }

.vidriera {
  margin-bottom: 25px;
  margin-top: 20px; }
  .vidriera a:hover, .vidriera a:focus, .vidriera a:active {
    color: #5a5a5a; }
  .vidriera h3 .title {
    text-align: center; }
    @media (min-width: 768px) {
      .vidriera h3 .title {
        text-align: left; } }
  .vidriera h3 span {
    display: block;
    width: 100%;
    color: #5a5a5a;
    margin: 0px; }
  .vidriera h3 small {
    font-size: 1rem;
    line-height: 1.3rem;
    display: inline-block;
    width: 100%;
    color: #5a5a5a;
    margin: 0px;
    margin-top: 10px; }
  .vidriera.bestsellers {
    border-top: 1px solid #912823;
    border-bottom: 1px solid #912823;
    padding: 2rem 0;
    background-color: #F8F8F8; }
    .vidriera.bestsellers .title {
      text-align: center; }
      .vidriera.bestsellers .title a {
        color: #5a5a5a;
        font-size: 3rem; }
        @media (min-width: 768px) {
          .vidriera.bestsellers .title a {
            font-size: 6rem; } }
  .vidriera .title a {
    color: #912823;
    margin-top: 0;
    font-family: Vollkorn, serif;
    font-size: 2rem;
    text-transform: uppercase; }
  .vidriera article figure a.img-container {
    border: none;
    outline: none; }
  .vidriera article figure img.img-responsive:focus {
    border: none;
    outline: none; }
  .vidriera article figure .oferta {
    background: var(--primary-color) none repeat scroll 0 0;
    color: #fff;
    font-weight: bold;
    left: 15px;
    padding: 5px 10px;
    position: absolute;
    top: 15px;
    z-index: 777; }
  .vidriera article figure figcaption {
    margin-top: 1rem; }
    .vidriera article figure figcaption h4 {
      font-family: Vollkorn, serif;
      font-weight: 400;
      color: #912823;
      font-size: 1.3rem;
      margin-bottom: .25rem;
      text-transform: uppercase;
      height: 38px;
      overflow: hidden;
      display: block; }
      @media (min-width: 768px) {
        .vidriera article figure figcaption h4 a {
          font-size: 1.12rem; } }
      @media (min-width: 992px) {
        .vidriera article figure figcaption h4 {
          height: 53px; } }
    .vidriera article figure figcaption p.autor {
      height: 52px;
      max-height: 127px;
      overflow: hidden;
      text-transform: uppercase;
      margin-bottom: .25rem;
      display: block; }
      .vidriera article figure figcaption p.autor a {
        color: #5a5a5a; }
        @media (min-width: 768px) {
          .vidriera article figure figcaption p.autor a {
            font-size: 0.95rem; } }
    .vidriera article figure figcaption .boton {
      border-top: 1px solid #912823;
      border-bottom: 1px solid #912823; }
      .vidriera article figure figcaption .boton a {
        color: #912823;
        font-family: Vollkorn, serif;
        font-size: 1.3rem;
        text-align: left;
        padding-left: 0px;
        padding-right: 0px;
        display: block; }
        @media (min-width: 992px) {
          .vidriera article figure figcaption .boton a {
            font-size: 1.12rem; } }
      .vidriera article figure figcaption .boton:nth-child(2) a {
        text-align: right; }
      .vidriera article figure figcaption .boton:hover a, .vidriera article figure figcaption .boton:hover .comprar i {
        color: #5a5a5a; }

.pagina-ficha-producto h1 {
  font-family: Vollkorn, serif;
  font-weight: normal;
  margin-top: 0.75rem;
  font-size: 1.9rem; }
  .pagina-ficha-producto h1 a {
    color: #912823; }

.pagina-ficha-producto .autor {
  font-family: Vollkorn, serif; }
  .pagina-ficha-producto .autor a {
    color: #912823;
    font-size: 1.25rem; }

.pagina-ficha-producto .subtitle, .pagina-ficha-producto .resenia {
  font-family: Lato, sans-serif;
  font-size: 1rem;
  color: #5a5a5a; }

.pagina-ficha-producto .subtitle {
  font-weight: 300; }

.pagina-ficha-producto .overflow-show {
  color: #912823;
  font-family: Lato, sans-serif;
  font-size: 1.2rem; }

.pagina-ficha-producto .contenedorDetalle {
  border-top: 1px solid #912823;
  margin-top: 10px;
  background: #F8F8F8;
  padding-bottom: 15px; }
  .pagina-ficha-producto .contenedorDetalle h3 {
    color: #912823;
    font-family: Vollkorn, serif;
    font-size: 1.3rem;
    font-weight: normal;
    text-transform: uppercase;
    margin-top: 0.75rem; }
  .pagina-ficha-producto .contenedorDetalle .lista > ul > li {
    list-style: none;
    margin-bottom: 0.75rem;
    clear: both;
    color: #5a5a5a; }
    .pagina-ficha-producto .contenedorDetalle .lista > ul > li li {
      list-style: none; }
    .pagina-ficha-producto .contenedorDetalle .lista > ul > li b {
      text-transform: uppercase; }

.pagina-ficha-producto .contenidoExtra .scroll-x {
  overflow-x: scroll; }
  @media (min-width: 992px) {
    .pagina-ficha-producto .contenidoExtra .scroll-x {
      overflow-x: hidden; } }

.pagina-ficha-producto .contenidoExtra .btn-group .btn {
  opacity: 0.7; }
  .pagina-ficha-producto .contenidoExtra .btn-group .btn:not(.collapsed) {
    opacity: 0.9; }
  .pagina-ficha-producto .contenidoExtra .btn-group .btn i {
    font-size: 2rem;
    color: #912823; }
  .pagina-ficha-producto .contenidoExtra .btn-group .btn span.label {
    font-family: Lato, sans-serif;
    font-size: 1rem;
    font-weight: 700;
    color: #912823; }

.pagina-ficha-producto .contenedorPrecios .precio {
  font-size: 1.45rem;
  text-align: left;
  color: #912823; }
  .pagina-ficha-producto .contenedorPrecios .precio .precio-ant {
    text-decoration: line-through;
    opacity: 0.55; }

.pagina-ficha-producto .contenedorPrecios .contenedorBoton {
  border-top: 1px solid #912823;
  border-bottom: 1px solid #912823;
  padding: 0.75rem; }
  .pagina-ficha-producto .contenedorPrecios .contenedorBoton .itemPrecios {
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    margin-bottom: 0.5rem; }
    .pagina-ficha-producto .contenedorPrecios .contenedorBoton .itemPrecios h6 {
      color: #5a5a5a;
      font-weight: 700;
      font-weight: 1.12rem;
      margin-bottom: 0.25rem; }
      @media (min-width: 768px) {
        .pagina-ficha-producto .contenedorPrecios .contenedorBoton .itemPrecios h6 {
          margin-bottom: 0.75rem; } }
    .pagina-ficha-producto .contenedorPrecios .contenedorBoton .itemPrecios p {
      margin-bottom: 0px; }
  .pagina-ficha-producto .contenedorPrecios .contenedorBoton:nth-child(2n+1) {
    border-top: 0px; }
  .pagina-ficha-producto .contenedorPrecios .contenedorBoton .boton + .boton {
    margin-top: 0.75rem; }
  .pagina-ficha-producto .contenedorPrecios .contenedorBoton .boton .btn {
    font-size: 1.2rem;
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    padding-left: 2rem;
    padding-right: 2rem; }
    @media (min-width: 768px) {
      .pagina-ficha-producto .contenedorPrecios .contenedorBoton .boton .btn {
        padding-left: 1.2rem;
        padding-right: 1.2rem; } }
  .pagina-ficha-producto .contenedorPrecios .contenedorBoton .mensajes-container {
    color: #5a5a5a;
    font-size: 1.2rem;
    margin-top: 0.75rem; }
    @media (min-width: 768px) {
      .pagina-ficha-producto .contenedorPrecios .contenedorBoton .mensajes-container {
        font-size: 0.85rem;
        margin-top: 0rem; } }
    .pagina-ficha-producto .contenedorPrecios .contenedorBoton .mensajes-container a {
      color: #5a5a5a; }

.pagina-carrito {
  color: #5a5a5a; }
  .pagina-carrito h1 {
    font-size: 1.4rem; }
  .pagina-carrito h3.title small {
    font-size: 1.1rem; }
  .pagina-carrito .alert.alert-danger {
    margin-bottom: 0px;
    margin-top: 20px;
    padding: 7px; }
  .pagina-carrito .alert.alert-warning {
    margin-top: 20px; }
  .pagina-carrito .md-overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0.5;
    background-color: #CCC;
    z-index: 100; }
  .pagina-carrito .listado {
    border-top: 1px solid #CCCCCC;
    margin-top: 15px; }
    .pagina-carrito .listado .headlabel {
      font-size: 1.2rem;
      margin: .5rem 0px; }
    .pagina-carrito .listado .header-row {
      border-bottom: 1px solid #CCCCCC;
      margin-bottom: 15px; }
    .pagina-carrito .listado .item {
      border-bottom: 1px solid #eaeaea;
      padding-bottom: .75rem;
      padding-top: .75rem; }
      .pagina-carrito .listado .item h4 a {
        text-transform: uppercase;
        font-size: 1.2rem; }
      .pagina-carrito .listado .item .autores a {
        text-transform: uppercase; }
      .pagina-carrito .listado .item .headlabel {
        font-size: 1rem; }
  .pagina-carrito .cupon-container .input-group {
    border: 1px solid #912823;
    padding: 0.3rem; }
    .pagina-carrito .cupon-container .input-group .input-group-addon {
      display: block;
      width: 100%; }
  .pagina-carrito .totales .item {
    padding: .5rem 0px;
    text-align: center;
    align-items: center; }
    .pagina-carrito .totales .item .total-title {
      font-size: 1.2rem; }
    .pagina-carrito .totales .item .total-value {
      font-size: 1.3rem; }
  .pagina-carrito .botones-group {
    flex-direction: column-reverse;
    margin: .75rem 0px; }
    @media (min-width: 768px) {
      .pagina-carrito .botones-group {
        flex-direction: row; } }

.pagina-especial {
  color: #5a5a5a; }
  .pagina-especial h1 {
    font-size: 2rem; }
  .pagina-especial h2 {
    font-size: 1.6rem; }
  .pagina-especial h3 {
    font-size: 1.3rem; }
  .pagina-especial h4 {
    font-size: 1.2rem; }
  .pagina-especial ul {
    margin-left: 0.75rem; }

.pagina-resultados .busqueda {
  padding: 0.9rem 0; }

.pagina-resultados .subtitle {
  font-size: 1.1rem; }

.pagina-resultados .cont-subheader {
  background: #F8F8F8; }

.pagina-resultados .palabraBusqueda {
  color: #912823;
  font-size: 1.23rem;
  font-weight: 400; }

.pagina-resultados .mosaico article figure a.img-container {
  border: none;
  outline: none; }

.pagina-resultados .mosaico article figure img.img-responsive:focus {
  border: none;
  outline: none; }

.pagina-resultados .mosaico article figure .oferta {
  background: var(--primary-color) none repeat scroll 0 0;
  color: #fff;
  font-weight: bold;
  left: 15px;
  padding: 5px 10px;
  position: absolute;
  top: 15px;
  z-index: 777; }

.pagina-resultados .mosaico article figure figcaption {
  margin-top: 1rem; }
  .pagina-resultados .mosaico article figure figcaption h4 {
    font-family: Vollkorn, serif;
    font-weight: 400;
    color: #912823;
    font-size: 1.3rem;
    margin-bottom: .25rem;
    text-transform: uppercase;
    height: 38px;
    overflow: hidden;
    display: block; }
    @media (min-width: 768px) {
      .pagina-resultados .mosaico article figure figcaption h4 a {
        font-size: 1.12rem; } }
    @media (min-width: 992px) {
      .pagina-resultados .mosaico article figure figcaption h4 {
        height: 53px; } }
  .pagina-resultados .mosaico article figure figcaption p.autor {
    height: 52px;
    max-height: 127px;
    overflow: hidden;
    text-transform: uppercase;
    margin-bottom: .25rem;
    display: block; }
    .pagina-resultados .mosaico article figure figcaption p.autor a {
      color: #5a5a5a; }
      @media (min-width: 768px) {
        .pagina-resultados .mosaico article figure figcaption p.autor a {
          font-size: 0.95rem; } }
  .pagina-resultados .mosaico article figure figcaption .boton {
    border-top: 1px solid #912823;
    border-bottom: 1px solid #912823; }
    .pagina-resultados .mosaico article figure figcaption .boton a {
      color: #912823;
      font-family: Vollkorn, serif;
      font-size: 1.3rem;
      text-align: left;
      padding-left: 0px;
      padding-right: 0px;
      display: block; }
      @media (min-width: 992px) {
        .pagina-resultados .mosaico article figure figcaption .boton a {
          font-size: 1.12rem; } }
    .pagina-resultados .mosaico article figure figcaption .boton:nth-child(2) a {
      text-align: right; }
    .pagina-resultados .mosaico article figure figcaption .boton:hover a, .pagina-resultados .mosaico article figure figcaption .boton:hover .comprar i {
      color: #5a5a5a; }

.pagina-resultados .mosaico article {
  margin-bottom: 1.5rem; }

.filtros {
  color: #5a5a5a; }
  @media (min-width: 992px) {
    .filtros {
      padding: 0.9rem 0; } }
  .filtros h5 {
    color: #912823;
    font-family: Vollkorn, serif; }
  .filtros h6 {
    color: #5a5a5a;
    font-family: Vollkorn, serif;
    font-weight: 500;
    font-size: 0.98rem;
    margin: 0.5rem 0;
    text-transform: uppercase;
    position: relative; }
    @media (min-width: 992px) {
      .filtros h6 {
        padding: 0.5rem 0;
        margin-bottom: 0.25rem;
        font-size: 1.3rem;
        color: #912823; } }
  @media (min-width: 992px) and (min-width: 992px) {
    .filtros h6 {
      font-size: 1.13rem; } }
  .filtros .filtros_aplicados ul li a {
    display: block;
    background: url(/images/filtro-activo.png) #F8F8F8 no-repeat right 10px center;
    margin-bottom: 0.25rem;
    padding: 0.15rem; }
  .filtros nav {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem; }
    .filtros nav h6 {
      padding: 0.5rem 0;
      margin-bottom: 0.25rem;
      font-size: 1.3rem;
      color: #912823; }
      @media (min-width: 992px) {
        .filtros nav h6 {
          font-size: 1.13rem; } }
      .filtros nav h6.collapsable:after {
        content: "\f068";
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        font-size: 1rem;
        position: absolute;
        right: 0px; }
      .filtros nav h6.collapsable.collapsed:after {
        content: "\f067"; }
      @media (min-width: 992px) {
        .filtros nav h6.collapsable:after {
          display: none; } }
    .filtros nav ul {
      max-height: 25vw;
      overflow-y: auto; }
      .filtros nav ul li {
        text-align: center; }
        @media (min-width: 992px) {
          .filtros nav ul li {
            text-align: left;
            padding: 0.15rem 0; } }
        .filtros nav ul li a {
          color: #5a5a5a; }
          @media (min-width: 992px) {
            .filtros nav ul li a {
              font-size: 0.9rem; } }
  .filtros .orden2 {
    padding-left: calc(0.2rem*1); }
  .filtros .orden3 {
    padding-left: calc(0.2rem*2); }
  .filtros .orden4 {
    padding-left: calc(0.2rem*3); }
  .filtros .orden5 {
    padding-left: calc(0.2rem*4); }
  .filtros .list-orden-header, .filtros .list-criterio-header {
    display: flex;
    justify-content: center; }

/**

    .cont-izquierda .filtros h6.collapsable.collapsed:after {
    content: "\f067";
}

.cont-izquierda .filtros h6.collapsable:after {
    content: "\f068";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    font-size: 22px;
    color: #DE7083;
    float: right;
}


*/
.filtros_mobile .filtros_aplicar .btn {
  color: #5a5a5a;
  border-top: 1px solid #912823;
  border-bottom: 1px solid #912823;
  margin: 0.5rem 0; }

.pagination li {
  display: flex; }
  .pagination li a, .pagination li span {
    color: #5a5a5a;
    font-size: 1.2rem; }
    .pagination li a:focus, .pagination li a:hover, .pagination li span:focus, .pagination li span:hover {
      color: #912823;
      font-weight: 700; }
    .pagination li a.anterior, .pagination li a.posterior, .pagination li span.anterior, .pagination li span.posterior {
      align-self: center; }
  .pagination li.active a {
    color: #912823;
    font-weight: 700; }
    .pagination li.active a:focus, .pagination li.active a:hover {
      color: #912823; }
  .pagination li.active span {
    color: #912823;
    font-weight: 700; }
    .pagination li.active span:focus, .pagination li.active span:hover {
      color: #912823; }

.pagina-listado-locales {
  color: #5a5a5a; }
  .pagina-listado-locales h1 {
    color: #912823;
    font-family: Vollkorn, serif;
    font-size: 1.9rem; }
  .pagina-listado-locales h3 {
    font-size: 1.45rem; }
  .pagina-listado-locales .card {
    margin: 0.25rem 0rem;
    border-radius: 0; }

footer {
  background: #912823;
  margin-top: 20px;
  color: #FFFFFF;
  padding-top: 1.1rem;
  padding-bottom: 1.1rem; }
  footer a:hover, footer a:focus, footer a:active {
    color: #5a5a5a; }
  footer ul li {
    list-style: none;
    text-align: left;
    font-weight: 300; }
    footer ul li.header-title, footer ul li.header-title > a, footer ul li aside.mail-novedades h4 {
      color: #FFFFFF;
      font-size: 18px;
      font-family: Lato, sans-serif;
      font-weight: 600;
      text-align: left;
      width: 100%;
      margin-bottom: 10px;
      text-transform: uppercase;
      display: block; }
      @media (min-width: 768px) {
        footer ul li.header-title, footer ul li.header-title > a, footer ul li aside.mail-novedades h4 {
          font-size: 18px;
          font-weight: 700; } }
    footer ul li ul li > a {
      color: #FFFFFF;
      font-size: 1rem;
      text-transform: none; }
  footer aside.mail-novedades h4 {
    color: #FFFFFF;
    font-size: 18px;
    font-family: Lato, sans-serif;
    font-weight: 600;
    text-align: left;
    width: 100%;
    margin-bottom: 10px;
    text-transform: uppercase;
    display: block; }
    @media (min-width: 768px) {
      footer aside.mail-novedades h4 {
        font-size: 18px;
        font-weight: 700; } }
