

:root {
    --third-color: #F8F8F8;
    --secondary-color: #5a5a5a;
    --primary-color: #912823;
    --main-outline-border: 1px solid #912823;
    --main-outline-white-border: 1px solid #ffffff;
    --placeholder-white: #FFFFFF;
    --placeholder-dark: #912823;
    --copyright-color: #FFFFFF;
    --newsletter-description-color: #FFFFFF;
    --font-primary: Lato,sans-serif;
    --font-secondary: Vollkorn, serif;
}

html {
    font-size: 13px;
}

body {
    font-family: Lato,sans-serif;
    background: none;
    color: #000000;
    font-weight: 400;
    font-size: 13px;
    line-height: 22px;
    overflow-x: hidden;
}

a:hover {
    text-decoration: none;
}

.mensajePromo {
    background-color: #F8F8F8;
    height: 30px;
    color: #A7A9B4;
    margin-left: -15px;
    font-family: Montserrat,sans-serif;
    font-weight: 500;
    font-size: 12px;
    margin-right: -15px;
    padding-top: 4px;
    text-transform: uppercase;
}


.menu ul.nav li {
    /*  flex: 1;
    margin: auto !important;
    display: flex;
    justify-content: space-around;*/
    text-align: left;
}

/*.menuprincipal {
    margin-top: 16px;
    padding-left: 14.5%;
    margin-bottom: 20px;
}
*/
.menuprincipal ul li:after {
    /* content: "/";
        text-align: center;
        font-weight: 800;
        font-family: Montserrat,sans-serif;
        font-size: 18px;
        color: #4B5253;*/
}

.menuprincipal ul li:last-child:after {
    display: none;
}

.dropdown-menu.show {
    display: inline-flex;
    width: 100%;
    /* position: relative; */
    top: 46px;
    /*  background: #F7E5F3;*/
    border: none;
    left: 0px;
    float: left;
    /*   height: 376px;*/
    position: absolute;
    left: -354px;
    right: 0;
    display: fixed;
    margin-left: 0px;
    width: 1360px;
    margin: 0 auto;
}
/*
header {
    background: #FFF;
    box-shadow: 1px 1px 1px var(--primary-color);
}*/

.contenedorCarrito .dropdown-menu.show {
    display: block;
    height: auto;
}


.editoriales .dropdown-menu.show {
    left: 0;
}

.menuprincipal li.editoriales.show > .dropdown-menu {
    background: url(/images/menu-caret-down.png) 54% -3px no-repeat #F7E5F3;
    padding: 25px 15px;
}

.menuprincipal li.autores.show > .dropdown-menu {
    background: url(/images/menu-caret-down.png) 80% -3px no-repeat #F7E5F3;
    padding: 25px 15px;
    position: absolute;
    left: -996px;
}

.menu ul.nav .dropdown-menu li {
    margin: 0 !important;
    display: inline-block;
}

    .menu ul.nav .dropdown-menu li.banner a {
        float: left;
        text-align: left;
    }

.menu ul.nav .dropdown-menu li.banner a span.cat-texto {
    margin-top: -43px;
    position: relative;
    /* font-family: Jura; */
    float: left;
    /* max-width: 100px; */
    /* text-align: center; */
    background: white;
    margin-left: 17px;
    padding: 1px;
    font-size: 14px;
    padding-left: 9px;
    padding-right: 6px;
    /* color: #EB008B;*/
}

.navbar-default ul li.header-title {
    font-family: Montserrat,sans-serif;
    font-weight: 600;
    font-size: 18px;
    color: #DE7083;
    text-transform: uppercase;
    text-align: left;
    float: left;
    margin-bottom: 10px;
}

    .navbar-default ul li.header-title a {
        padding-left: 0;
        font-weight: 600;
        font-size: 18px;
        color: #DE7083;
        text-transform: uppercase;
        text-align: left;
        float: left;
        margin-bottom: 10px;
    }

.navbar-default ul li ul li:after {
    display: none;
}

.navbar-default ul li.header-title ul li {
    float: left;
    width: 100%;
}

    .navbar-default ul li.header-title ul li a {
        color: #002E6D;
        font-family: Montserrat,sans-serif;
        font-weight: 400;
        text-transform: none;
        float: left;
        width: 100%;
        font-size: 16px;
        padding-left: 0;
        margin-bottom: 0;
    }

        .navbar-default ul li.header-title ul li a:hover {
            font-weight: 600;
        }

/*.navbar-default ul li ul li a {
    color: #4B5253;
    font-family: Montserrat,sans-serif;
    font-weight: 400;
    text-transform: none;
    float: left;
    width: 100%;
    font-size: 16px;
    margin-bottom: 0;
}
*/

/*.navbar-default ul li a {
    font-family: var(--font-secondary);
    font-size: 18px;
    padding-left: .75rem;
    padding-right: .75rem;
    padding-top: 0;
    color: var(--primary-color);
    text-transform: uppercase;
}

    .navbar-default ul li a:not([href]):not([tabindex]) {
        color: var(--primary-color);
    }

        .navbar-default ul li a:not([href]):not([tabindex]):hover {
            color: var(--secondary-color);
            cursor: pointer;
        }

    .navbar-default ul li a:hover {
        color: var(--secondary-color);
        text-decoration: none;
    }

.navbar-default ul.contenedorMiCuenta .registracionTablet {
    float: left;
}

.navbar-default ul.contenedorMiCuenta li a {
    font-size: 12px;
    font-weight: 600;
    padding: 6px;
    display: table-cell;
    margin-left: 11px;
    float: left;
    font-family: Montserrat,sans-serif;
    color: #06326B;
}

    .navbar-default ul.contenedorMiCuenta li a:hover {
        color: #DE7083;
    }

.navbar-default .header .colLogo {
    z-index: 90;
}

.navbar-default ul.contenedorMiCuenta li a span.badge {
    margin-left: 0;
    padding: 0;
}

.navbar-default ul.contenedorMiCuenta li a span.cantidad {
    margin: 0;
    padding: 0;
    text-align: center;
    font-size: 13px;
    width: auto;
    left: 2px;
}*/

.cartEmpty .badge {
    display: none;
}
/*.navbar-default .buscador {
    padding-left: 0;
}*/

.navbar-default ul.contenedorMiCuenta li a.carrito {
    color: #DE7083;
}


/*
.buscadorSelect .btn {
    color: #000;
    border: none;
    border-bottom: 2px solid #000;
    border-radius: 0;
    font-size: 16px;
    font-weight: 300;
    border-top: none;
    height: 36px;
    font-family: Oswald,sans-serif;
    background: none;
    outline: none;
    padding-top: 0;
    margin-right: 8px;
    margin-top: 1px;
}

.buscador .input-group-btn button img {
    max-height: 100%;
    width: 58px;
}*/

/*
.buscadorSelect .btn.dropdown-toggle::after {
    color: #F94343;
    content: "\f078";
    font-family: "Font Awesome 5 Free";
    border: none;
    border: none;
    position: relative;
    border-top: none;
    outline: none;
    font-weight: 900;
}


*/
.ui-state-focus, .ui-state-hover, .ui-widget-content .ui-state-focus, .ui-widget-content .ui-state-hover, .ui-widget-header .ui-state-focus, .ui-widget-header .ui-state-hover {
    border: 1px solid #002E6D;
    background: #fff;
    color: var(--primary-color, #912823);
}
/*Font Tracking 200 = letter-spacing: 3px;*/

/*h1, .h1 {
    text-align: left;
    text-transform: uppercase;
    font-family: Montserrat,sans-serif;
    font-weight: 700;
    color: #002E6D;
    font-size: 32px;
}
*/
/*.cont-central h1 {
    margin-bottom: 15px;
}*/

/*h1 a {
    color: #DE7083;
}*/

hr {
    border-top: 1px solid #C8C8C4;
    margin-bottom: 0;
}

/*.grey {
    color: #000000;
}

.red {
    color: #f94343;
}*/

/*pagina de formularios*/
.pagina-formulario .cont-subheader {
    /* margin-top:-12px;*/
}

/*.formulario .btn {
    font-family: Montserrat,sans-serif;
    font-weight: 600;
    height: 48px;
    background: #DE7083;
    border: 2px solid #DE7083;
    color: #fff;
}

    .formulario .btn:hover {
        background: #fff;
        border: 2px solid #DE7083;
        color: #DE7083;
    }*/
/*SLIDER*/
/*.slider-container {
    margin-bottom: 20px;
}
*/
.flex-grow {
    flex: 1 0 auto;
}

.cont-subheader {
    /*   margin-top: -22px;*/
}

.sp-buttons {
    text-align: center;
    margin-top: -4%;
}

    .sp-buttons .sp-button {
        background: transparent;
        border-width: 1px;
        border-color: var(--secondary-color);
        width: 40px;
        height: 5px;
        border-radius: 0px;
    }

.sp-next-arrow:after, .sp-next-arrow:before, .sp-previous-arrow:after, .sp-previous-arrow:before {
    width: 20% !important;
}

.sp-button.sp-selected-button {
    background: var(--primary-color);
    border: var(--primary-color);
}
/*BANNERS*/
.banner .info h4 {
    /* display: none; */
    float: right;
    width: auto;
    font-family: Montserrat,sans-serif;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 11px;
    margin-top: 5px;
    color: #4B5253;
}

.beneficiosHome {
    padding-left: 100px;
    padding-right: 100px;
    border-top: 1px solid #E8E9EC;
}

    .beneficiosHome .separadorVertical {
        border-right: 1px solid #E8E9EC;
    }

.banner {
    margin-bottom: 25px;
}

.beneficiosHome h4 {
    font-size: 14px;
    font-weight: 600;
    font-family: Montserrat,sans-serif;
    color: #06326B;
    margin-top: 10px;
}

.beneficiosHome p {
    font-family: 'Gothic A1',sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #A7A9B4;
}

.beneficiosHome img {
    height: 35px;
}
/*VIDRIERAS FAVORITO*/

/*.vidriera h3 span {
    display: block;
}

.vidriera h3 small {
    font-size: 1rem;
    line-height: 1.3rem;
    display: inline-block;
}

.vidriera figcaption {
    margin-top: 1rem;
}*/

/*productos favoritos*/
/*.vidriera .filtrosVidriera .nav-item span {
    padding-top: 5px;
    position: relative;
    float: left;
    color: #F94343;
    font-family: Esteban,serif;
    font-size: 16px;
    font-weight: 400;
}

.vidriera .filtrosVidriera .nav-item a.nav-link {
    padding-top: 5px;
    position: relative;
    float: left;
    color: #666666;
    font-family: Oswald,sans-serif;
    font-size: 16px;
    font-weight: 400;
}

    .vidriera .filtrosVidriera .nav-item a.nav-link:hover, .vidriera .filtrosVidriera .nav-item a.nav-link.selected {
        text-decoration: underline;
    }*/

.border-bottom {
    border-bottom: var(--main-outline-border) !important;
}

/*.vidriera h4 {
    font-family: var(--font-secondary);
    font-weight: 400;
    color: var(--primary-color);
    font-size: 1.2rem;
    margin-bottom: 25px;
}

.vidriera.bestsellers {
    border-top: var(--main-outline-border);
    border-bottom: var(--main-outline-border);
    padding: 2rem 0;
    background-color: var(--third-color);
}*/

/*.pagina-home .vidriera .title a, .pagina-categorias .vidriera .title a {
    color: var(--primary-color);
    margin-top: 0;
    font-family: var(--font-secondary);
    font-size: 2rem;
}
*/

/*.pagina-home .vidriera.bestsellers .title a {
    color: var(--secondary-color);
    font-size: 3rem;
}*/

.pagina-categorias .menu-especialidades a.catPrincipal {
    font-weight: 800;
    font-family: Montserrat,sans-serif;
    font-size: 18px;
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 0;
    color: #002E6D;
    text-transform: uppercase;
}

.pagina-categorias .header-title {
    margin-bottom: 4px;
}

.menu-especialidades a {
    text-transform: uppercase;
    padding-left: 10px;
}

.masVendidos .vidriera .title a {
    font-family: Montserrat,sans-serif;
    font-weight: 700;
    font-size: 32px;
    padding-right: 0;
    font-size: 32px;
    color: #DE7083;
    -webkit-background-clip: text;
    /* -webkit-text-fill-color: transparent; */
    background: none;
    -webkit-background-clip: text;
    /* -webkit-text-fill-color: transparent; */
    margin-top: 3px;
    float: left;
    text-align: center;
    width: 100%;
}

.masVendidos span.orden {
    font-family: Montserrat,sans-serif;
    font-weight: 700;
    font-size: 88px;
    background: linear-gradient(to bottom, #DE7083 36%,#F8E2E6 73%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-left: 28%;
}

.masVendidos ul li {
    min-height: 110px;
}

.masVendidos h5 a {
    margin-top: -22px;
    font-family: Montserrat,sans-serif;
    font-size: 20px;
    color: #DE7083;
    text-transform: uppercase;
    margin-top: -20px;
    float: left;
    font-weight: 600;
}

}

.header-vidriera {
    margin-bottom: 20px;
}

.masVendidos .verMas a {
    /*background-image: url(/images/btnFondo.png);
    */ height: 54px;
    width: 153px;
    display: inline-block;
    background-repeat: repeat;
    padding: 13px;
    margin-bottom: 83px;
    font-family: Montserrat,sans-serif;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 500;
    border: 2px solid;
}

    .masVendidos .verMas a:hover {
        background: #DE7083;
        border-color: #DE7083;
        color: #fff;
    }

.masVendidos .vidriera .autor a {
    font-size: 17px;
    float: left;
    width: auto;
    margin-right: 8px;
    margin-bottom: 0px;
    margin-top: 4px;
    /* height: 12px; */
    color: #EBA9B5;
    line-height: 17px;
}

/*#contendorFooter {
    background: var(--primary-color);
    margin-top: 20px;
}
*/
/*.vidriera.ofertas {
    background: #FBCCE8;
    padding: 21px;
    margin-bottom: 8px;
    padding-bottom: 0px;
}

    .vidriera.ofertas .slick-prev, .vidriera.ofertas .slick-next {
        display: none !important;
    }

    .vidriera.ofertas article h4 {*/
/* display:none;*/
/*}

    .vidriera.ofertas .tapa {
        min-height: auto;
        margin-bottom: 15px;
    }

        .vidriera.ofertas .tapa img {
            max-height: 280px;
            margin: 0 auto;
        }

    .vidriera.ofertas article h4 a {*/
/*   display: none;*/
/*}

    .vidriera.ofertas figcaption {*/
/* display:none;*/
/*}

    .vidriera.ofertas p.autor {
        display: none;
    }

    .vidriera.ofertas .title a, .vidriera.ofertas .title small {
        background: transparent;
        margin-left: -15px;
    }*/

.masVendidos li.item > div {
    display: inline-block;
    -webkit-align-items: center;
    -webkit-box-align: center;
    align-items: center;
}

.instagram h2 a {
    display: inline-block;
    margin-top: 30px;
    color: var(--primary-color);
    font-family: Lato,sans-serif;
    font-weight: 700;
    border-top: var(--main-outline-border);
    font-size: 1.5rem;
    border-bottom: var(--main-outline-border);
}


/*.vidriera {
    margin-bottom: 25px;
    margin-top: 20px;
}
*/
.masVendidos .header-vidriera {
    margin-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 42px;
}

    .masVendidos .header-vidriera img {
        float: left;
        margin-right: 6%;
        margin-left: 0%;
    }

.masVendidos .vidriera h3.title span {
    float: left;
    width: 100%;
}

.contenedorBannerCentral {
    /* background: #FBCCE8; */
    padding-bottom: 31px;
    /* padding: 7px;*/
}

    .contenedorBannerCentral .banner {
        margin-bottom: 0;
    }

.masVendidos {
    border: 5px solid #DE7083;
}

    .masVendidos .vidriera h3.title {
        float: left;
        width: 100%;
    }

    .masVendidos .border {
        /* max-width: 250px; */
        /* padding: 1rem;
    position: relative;
    background: linear-gradient(to right, #EB008B, #66CEF5);
    padding: 4px;*/
    }

    .masVendidos .vidriera {
        background: #fff;
        margin-bottom: 0;
    }

/*.vidriera h3.title {
    text-align: center;
}*/

/*.pagina-ficha-producto .vidriera h3.title a {
    font-family: Montserrat,sans-serif;
    font-weight: 700;
    font-size: 26px;
    color: #002E6D;
    text-transform: uppercase;
}*/
/*
.vidriera h3.title span, .vidriera h3.title small {
    width: 100%;
    color: var(--secondary-color);
    margin: 0px;
}

.vidriera h3.title small {
    margin-top: 10px;
}
*/
.slick-list, .slick-slider, .slick-track {
    /* display:inline-flex !important;*/
}

/*.vidriera article h4 a, .listado-resultados article h4 a {
    color: var(--primary-color);
    font-family: var(--font-secondary);
    font-size: 1.3rem;
    display: inline-block;*/
/*    text-align: center;*/
/*text-transform: uppercase;
    width: 100%;
}*/
/*
.vidriera p.autor, .listado-resultados article p.autor {
    height: 52px;
    max-height: 127px;
    overflow: hidden;
}*/

.widgetHomeContenedor {
    background: none;
    padding-top: 25px;
    padding-bottom: 0px;
    padding-left: 2%;
    padding-right: 2%;
    margin-bottom: 10px;
}

    .widgetHomeContenedor .contenedorImagen {
        background: white;
        padding: 19px;
        padding-left: 4px;
        padding-right: 4px;
    }

    .widgetHomeContenedor div.info {
        background: white;
        margin-left: 7%;
        margin-right: 7%;
        margin-top: -46px;
        padding: 13px;
        float: left;
    }

    .widgetHomeContenedor .banner a.lnkVerMas {
        background-image: url(/images/Btn.svg);
        background-repeat: no-repeat;
        background-position: center;
        height: 38px;
        /* float: left; */
        position: relative;
        display: flex;
        width: 103px;
        margin: 0 auto;
        margin-top: 16px;
        margin-bottom: 11px;
        padding-top: 4px;
    }

        .widgetHomeContenedor .banner a.lnkVerMas:hover {
            color: #fff;
            text-decoration: none;
            background-image: url(/images/btnHover.svg);
        }

    .widgetHomeContenedor .info h4 {
        float: left;
        width: 100%;
        font-family: Montserrat,sans-serif;
        font-weight: 400;
        text-transform: none;
        font-size: 22px;
        margin-top: 5px;
        color: #000;
    }

    .widgetHomeContenedor h5 {
        font-family: Montserrat,sans-serif;
        font-weight: 400;
        font-size: 16px;
        color: #4B5253;
    }

        .widgetHomeContenedor h5 span {
            height: 21px;
            float: left;
            overflow: hidden;
        }

    .widgetHomeContenedor .filaWidget {
        overflow: hidden;
    }

.listado-resultados article p.autor {
}

/*.vidriera article.recomendado .tapa img {
    max-height: 513px;
}

.vidriera article.recomendado .tapa {
    float: left;
    width: 50%;
}
*/
.menu-especialidades a.toggle:after {
    content: '(-)';
    cursor: pointer;
    cursor: hand;
}

.menu-especialidades a.toggle.collapsed:after {
    content: '(+)';
    cursor: pointer;
    cursor: hand;
}

.menu-especialidades .subsubtema a {
    font-family: Montserrat,sans-serif;
    font-weight: 400;
    color: #002E6D;
    text-transform: none;
    font-size: 13px;
    padding-left: 15px;
}

.menu-especialidades .subsubsubtema a {
    font-family: Montserrat,sans-serif;
    font-weight: 400;
    color: #002E6D;
    text-transform: none;
    font-size: 13px;
    padding-left: 25px;
}
/*
.vidriera article.recomendado .resenia {
    padding-left: 5px;
    font-size: 14px;
    font-family: Montserrat,sans-serif;
    color: #4B5253;
    margin-top: 58px;
    position: relative;
    float: left;
    min-height: 262px;
    margin-bottom: 6px;
}*/

/*.contenidoExtra span.label {
    color: #002E6D;
    font-family: Montserrat, sans-serif;
    font-size: 14px;
    font-weight: 700;
}*/
/*
.vidriera article.recomendado .precio {
    text-align: left !important;
}

.vidriera article.recomendado figure .boton .btn.comprar {
    margin-left: 5px;
    background-position: left;
    text-align: left;
    padding-left: 6%;
}


.vidriera article.itemRecomendado .tapa {
    min-height: 303px;
    margin-bottom: 15px;
}

.vidriera article.itemRecomendado figure figcaption h4 {
    margin-bottom: 0;
}

.vidriera article.recomendado figcaption {
    float: left;
    width: 50%;
}

.vidriera article.recomendado h4 {
    height: auto;
}

.vidriera article.recomendado .boton {
    text-align: left;
    margin-left: 10px;
}

.vidriera article.recomendado p.autor {
    margin-top: 337px;
    margin-left: 0px;
    text-align: left !important;
    padding-left: 0;
}

.vidriera article.recomendado h4 a, .vidriera article.recomendado .autor a {
    text-align: left;
    padding-left: 10px;
    padding-top: 13px;
}

*/

/*.precio {
    font-family: Montserrat,sans-serif;
    font-weight: 500;
    font-size: 16px;
    color: #DE7083;
}*/

/*.vidriera .precio span, .listado-resultados .precio span {
    border-top: 1px solid #DE7083;
}
*/
/*.precio-oferta {
    margin-bottom: 0;
}*/

/*.vidriera .autor a, .listado-resultados article .autor {
    color: var(--secondary-color);
    font-size: 1.1rem;
    text-transform: uppercase;
  
}*/

/*.vidriera .oferta {
    background: var(--primary-color) none repeat scroll 0 0;
    color: #fff;
    font-weight: bold;
    left: 15px;
    padding: 5px 10px;
    position: absolute;
    top: 15px;
    z-index: 777;
}*/

.beneficiosHome h2 {
    font-family: Montserrat,sans-serif;
    font-weight: 700;
    font-size: 32px;
    font-size: 32px;
    background: -webkit-linear-gradient(to right,#eee, #333);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background: linear-gradient(to right, #E9408F 0%, #66CEF5 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.beneficiosHome .banner {
    margin-top: 10px;
}


.slick-dots {
    height: 39px;
    overflow: hidden;
}

    .slick-dots li button::before {
        color: #B7BABA !important;
        font-size: 40px !important;
        opacity: 1;
    }

    .slick-dots li.slick-active button::before {
        color: #FF0099 !important;
    }


/*.vidriera figure .boton .btn.comprar::after, .listado-resultados figure .boton .btn.comprar::after {
    outline: none;
    border: none;
}

.vidriera .precio-ant {
    font-size: 14px;
    text-decoration: line-through;
    padding-right: 15px;
}

.vidriera .precio-descuento {
    float: left;
    padding: 8px;
    font-size: 18px;
    color: #EB008B;
}

.vidriera figure img.img-responsive:focus {
    border: none;
    outline: none;
}

.vidriera figure a.img-container {
    border: none;
    outline: none;
}
*/


.slick-prev, .slick-next {
    top: 168px;
}

    .slick-next.slick-arrow {
        right: -19px;
    }

    .slick-prev.slick-arrow {
        left: -21px;
    }


    .slick-prev:before, .slick-next:before {
        color: #002E6D;
        font-size: 26px;
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
    }

    .slick-prev:before {
        content: '\f053';
    }

    .slick-next:before {
        content: '\f054';
    }


/*.vidriera figure figcaption .boton .comprar i {
    color: #000;
    margin-left: 0px;
}*/

/*.vidriera figure figcaption h4, .mosaico .listado-resultados figure figcaption h4 {
    margin-bottom: 5px;
    height: 50px;
    font-family: Montserrat,sans-serif;
    font-weight: 500;
    font-size: 16px;
    text-transform: uppercase;
    overflow: hidden;
}*/

/*.vidriera figure figcaption h4 {*/
/* margin-top: 20px;*/
/*}

.vidriera figure figcaption .boton i {
    margin-top: 2px;
    font-size: 16px;
}*/
/*
.vidriera .boton {
    border-top: var(--main-outline-border);
    border-bottom: var(--main-outline-border);
}

    .vidriera .boton a {
        font-family: var(--font-secondary);
        font-size: 1.3rem;
        text-align: left;
        padding-left: 0px;
        padding-right: 0px;
        display: block;
    }



    .vidriera .boton:nth-child(2) a {
        text-align: right;
    }

    .vidriera .boton:hover a, .vidriera .boton:hover .comprar i {
        color: var(--secondary-color);
    }*/

.vidriera .boton .consultar {
    /*        margin-left: 10px;
        margin-top: -1px;
        border: none;
        outline: none;
        color: #DE7083;
        border-top: 1px solid #DE7083;
        padding-top: 4px;*/
}

/*.vidriera .slick-dots {
    display: none !important;
}

.vidriera .boton .consultar::after {
    content: none;
}*/

/*.vidriera .tapa {
    min-height: 324px;
}

.novedades.vidriera .tapa {*/
/*    margin-left: 9px;
    margin-right: 9px;
    padding-left: 10px;
    padding-right: 10px;*/
/*}*/

/*.vidriera .tapa img {
    max-height: 338px;
}
*/
/*.vidriera .precio a, .listado-resultados .precio a {
    font-size: 16px;*/
/* padding-top: 10px; */
/*margin-left: 0px;
    font-weight: 300;*/
/* float: left; */
/*color: #DE7083;*/
/* margin-right: 5px; */
/*padding-top: 2px;
}*/

/*    .vidriera .precio a img, .listado-resultados .precio a img {
        margin-left: 10px;
        max-width: 21px;
        margin-top: -3px;
    }
*/

.slick-next:before {
    font-family: 'Font Awesome 5 Free';
}


/*SUB FOOTER*/
.cont-subfooter {
    padding-bottom: 25px;
}

    .cont-subfooter .acciones a {
        font-family: 'Roboto Condensed',sans-serif;
        font-weight: 400;
        font-size: 16px;
        color: #616161;
        text-transform: uppercase;
    }

        .cont-subfooter .acciones a i.fa {
            padding: 14px;
        }

.separador {
    display: inline-block;
    height: 1px;
    /* border: 1px solid; */
    width: 89%;
    margin-left: 5%;
    margin-bottom: 12px;
    /*  background: linear-gradient(to right, #E9408F 0%, #66CEF5 100%);*/
}

.separadorSimple {
    display: inline-block;
    background: url(/images/separadorSimple.jpg) repeat-x;
    position: relative;
    /* float: left; */
    height: 7px;
    /* margin-right: -1px; */
    overflow: hidden;
    width: 100%;
}

#menuFooter .redSocial a i {
    width: 48px;
    height: 48px;
    display: inline-block;
}

    #menuFooter .redSocial a i.facebook {
        background: url(/images/ic-facebook.png);
    }

    #menuFooter .redSocial a i.twitter {
        background: url(/images/ic-tw.png);
    }

    #menuFooter .redSocial a i.wapp {
        background: url(/images/ic-wapp.png);
    }

li.redSocial {
    /* padding-left: 11%; */
    width: 21%;
    margin-left: 9%;
    float: left;
}

.copyrightContainer {
    margin-top: 16px;
    margin-bottom: 15px;
    color: var(--copyright-color);
    font-family: Montserrat,sans-serif;
    font-weight: 300;
    font-size: 0.9rem;
}

.copyright a {
    color: #000;
}

/*REGISTRACION SUBFOOTER*/


/*
#menuFooter {
    padding-top: 15px;
    padding-bottom: 15px;
}

    #menuFooter > a {
        color: #A7A9B4;
        font-size: 16px;
        font-family: Montserrat,sans-serif;
        font-weight: 400;
        text-align: right;
        width: 100%;
    }*/

/*#menuFooter .contenedorSuscripcion {
        background: none;*/
/*        padding: 10px;*/
/*margin-top: 0;
    }*/

/*#menuFooter a:hover {
        color: #DE7083;
        text-decoration: none;
    }
*/
/*  #menuFooter ul li {
        list-style: none;
        text-align: left;
        font-weight: 300;
    }*/

/*#menuFooter .header-title > a, .cont-footer aside.mail-novedades h4 {
        color: #FFFFFF;
        font-size: 18px;
        font-family: Lato,sans-serif;
        font-weight: 600;
        text-align: left;
        width: 100%;
        margin-bottom: 10px;
        text-transform: uppercase;
        float: left;
    }*/

#menuFooter ul {
    /* padding-left: 0;
        line-height: 2;
        font-size: 14px;
        font-family: Oswald,sans-serif;
        font-weight: 300;
        width: 100%;*/
}




.cont-footer aside.mail-novedades {
    margin-bottom: 16px;
}

    .cont-footer aside.mail-novedades h4 {
        /* color: #002E6D;
        text-transform: uppercase;
        font-family: Montserrat,sans-serif;
        font-weight: 700;
        text-align: left;
        font-size: 18px;
        margin-bottom: 22px;
        margin-top: -2px;*/
    }

    .cont-footer aside.mail-novedades p {
        /* display: none; */
        color: var(--newsletter-description-color);
        text-align: left;
        /* font-family: Jura,sans-serif; */
        font-weight: 400;
    }

.mail .data input[type=email] {
    border-radius: 0px;
    border-left: 0px;
    border-top: 0px;
    border-right: 0px;
    border-bottom: var(--main-outline-white-border);
    background-color: transparent;
    color: #ffffff;
    height: calc(2.25rem + 13px);
    /*border-radius: 0;
    border: 1px solid #A7A9B4;
    width: 100%;
    display: block;*/
    /* height: 48px; */
    /*color: #A7A9B4;
    background: transparent;
    font-family: Montserrat, sans-serif;
    font-weight: 400;
    font-size: 16PX;
    float: left;
    margin-top: 5px;
    margin-right: 0;
    width: 73%;*/
}


#menuFooter a.redesSociales {
    color: #fff;
    font-size: 21px;
    font-family: Oswald,sans-serif;
    font-weight: 300;
    text-align: left;
    width: 100%;
    /* border: 2px solid; */
    margin-right: 0px;
    width: 30px;
    height: 30px;
    float: left;
    text-align: center;
    margin-bottom: 15px;
    position: relative;
    padding: 0;
    display: inline-table;
}

.formSuscripcion {
    background: url(/images/newsletter.jpg) no-repeat;
    height: 190px;
    background-size: contain;
    color: #000;
}

    .formSuscripcion input[type=checkbox] {
        box-sizing: border-box;
        padding: 0;
        border: 2px solid #fff;
        width: 17px;
        background: white;
        float: left;
        /* margin-top: 14px; */
    }

    .formSuscripcion .mail .data input[type=email] {
        border-radius: 0;
        /* border: 3px solid #DBDBDB; */
        width: 55%;
        display: block;
        /* height: 48px; */
        color: #000;
        background: transparent;
        font-family: Esteban,serif;
        font-weight: 400;
        font-size: 14PX;
        float: left;
        margin-top: 5px;
        margin-right: 10px;
        border-bottom: 2px solid;
    }

        .formSuscripcion .mail .data input[type=email]::-webkit-input-placeholder, .formSuscripcion .mail .data input[type=email]::-webkit-input-placeholder {
            color: #000;
        }

        .formSuscripcion .mail .data input[type=email]::-moz-placeholder, .formSuscripcion .mail .data input[type=email]::-moz-placeholder {
            color: #000;
        }

        .formSuscripcion .mail .data input[type=email]:-ms-input-placeholder, .formSuscripcion .mail .data input[type=email]:-ms-input-placeholder {
            color: #000;
        }

        .formSuscripcion .mail .data input[type=email]:-moz-placeholder, .formSuscripcion .mail .data input[type=email]:-moz-placeholder {
            color: #000;
        }


#menuFooter input[type=checkbox] {
    box-sizing: border-box;
    padding: 0;
    border: 2px solid #000;
    width: 17px;
    background: white;
    float: left;
    /* margin-top: 14px; */
}

#menuFooter .verificar, .formSuscripcion .verificar {
    margin-top: 15px;
}

    #menuFooter .verificar label, .formSuscripcion .verificar label {
        float: left;
        width: 85%;
        line-height: 1;
        font-family: Oswald,sans-serif;
        font-weight: 300;
        font-size: 12px;
    }

#menuFooter .mail .data a.suscribe, .formSuscripcion .mail .data a.suscribe {
    font-size: 20px;
    border-bottom: var(--main-outline-white-border);
    border-left: none;
}

    #menuFooter .mail .data a.suscribe img {
        height: 23px;
        margin-top: -4px;
    }

    #menuFooter .mail .data a.suscribe:hover, .formSuscripcion .mail .data a.suscribe:hover {
        background-color: transparent;
        color: #000;
        border-color: #000;
        border-radius: 0;
    }


.formSuscripcion .mail .data a.suscribe {
    border-color: #fff;
    color: #000;
    border-radius: 0;
    width: 38%;
}

    .formSuscripcion .mail .data a.suscribe:hover {
        background-color: transparent;
    }

.formSuscripcion .btn:after {
    border: 1px solid #000;
}

.mail .data input[type=email]::-webkit-input-placeholder, .mail .data input[type=email]::-webkit-input-placeholder {
    color: var(--placeholder-white);
}

.mail .data input[type=email]::-moz-placeholder, .mail .data input[type=email]::-moz-placeholder {
    color: var(--placeholder-white);
}

.mail .data input[type=email]:-ms-input-placeholder, .mail .data input[type=email]:-ms-input-placeholder {
    color: var(--placeholder-white);
}

.mail .data input[type=email]:-moz-placeholder, .mail .data input[type=email]:-moz-placeholder {
    color: var(--placeholder-white);
}


/*BLOG NOTAS*/
.pagina-notas h3 a, .pagina-nota-detalle h3 a {
    color: #000000;
    font-weight: 400;
    font-size: 35px;
}

.pagina-notas .destacado-lectura h3 a {
    font-size: 45px;
    text-transform: uppercase;
    width: 50%;
}


.pagina-notas .destacado-lectura h4.categoria {
    font-size: 16px;
}

.pagina-notas h1.h1 {
    margin-top: 34px;
    margin-bottom: 15px;
}

.pagina-notas .notas-listado .title {
    text-align: left;
    padding-bottom: 18px;
    font-weight: 400;
}

.pagina-notas .notas-listado article {
    margin-bottom: 3px;
    border-left: 2px solid;
}


    .pagina-notas .notas-listado article:after {
    }


.notas-listado a.categoria {
    font-size: 16px;
    font-family: Esteban,serif;
}

.pagina-notas .paginado {
    margin-bottom: 30px;
    margin-top: 10px;
}

.pagina-notas .nota a.tit {
    font-weight: 400;
    font-size: 18px;
    text-transform: uppercase;
}

.pagina-notas .nota .copete {
    font-size: 14px;
}

.pagina-notas .nota a.leerMas {
    font-size: 12px;
}

.pagina-nota-detalle h1 {
    text-align: center;
    font-size: 34px;
}

    .pagina-nota-detalle h1 a {
        text-align: center;
        color: #000000;
        text-transform: uppercase;
    }

.pagina-nota-detalle .imagenes {
    margin-top: 50px;
    margin-bottom: 40px;
}

    .pagina-nota-detalle .imagenes a img {
        height: 374px;
    }

.pagina-nota-detalle .cont-subheader {
    /* margin-top:-13px;*/
}

.pagina-nota-detalle .cuerpo {
    font-weight: 300;
}

.pagina-nota-detalle .header-vidriera h3.h1 a {
    color: #F94343;
}

.detalle-lectura h4.categoria {
    font-family: Esteban,serif;
}






.categoriasNotas button.btn {
    color: #000;
    border: none;
    border-bottom: 2px solid #000;
    border-radius: 0;
    font-size: 16px;
    font-weight: 300;
    border-top: none;
    height: 36px;
    font-family: Oswald,sans-serif;
    background: none;
    outline: none;
    padding-top: 0;
    margin-right: 8px;
    margin-top: 1px;
}

.categoriasNotas h4 {
    color: #F94343;
    font-family: Oswald,sans-serif;
    font-weight: 400;
    font-size: 16px;
    padding-top: 7px;
}

.categoriasNotas button.btn:after {
    color: #F94343;
    font-family: "Font Awesome 5 Free";
    content: "\f107";
    border: none;
    position: relative;
    border-top: none;
    font-weight: 900;
    outline: none;
}

/*Paginador*/
/*.pagination > li > a, .pagination > li > span {
    font-size: 30px;
    color: #002E6D;
    margin-right: 10px;
    font-family: Montserrat,sans-serif;
    font-weight: 700;
    border: none;
}*/

/*.pagination .fa, .pagination .fas {
    font-size: 21px;
}
*/
/*.pagination > .active > a, .pagination > .active > a:focus, .pagination > .active > a:hover, .pagination > .active > span, .pagination > .active > span:focus, .pagination > .active > span:hover {
    color: #002E6D;
    cursor: default;
    border-bottom: 3px solid #DE7083;
}*/




/*FICHA PRODUCTO*/
.pagina-ficha-producto .mainDetalleContainer {
    /*background: linear-gradient(90deg, #FBCCE8, #FCFCFC 68%);*/
    border-top: 1px solid #A7A9B4;
}

    .pagina-ficha-producto .mainDetalleContainer a.tapa img {
        /*   background: #fff;
        margin: 5px;
        padding: 24px;*/
    }

.pagina-ficha-producto .fa-align-center:before {
    color: transparent;
}



/*.pagina-ficha-producto .resenia {
    font-family: 'Gothic A1',sans-serif;
    font-size: 16px;
    color: #002E6D;
}
*/


.pagina-ficha-producto h1 {
}

/*.pagina-ficha-producto h1 a {
        color: #002E6D;
    }
*/
.fucsia {
    color: #DE7083;
}


.pagina-ficha-producto .contenedorDetalle .lista ul li a {
    /*   color: #000;
                text-transform: uppercase;*/
}

.pagina-ficha-producto .contenedorDetalle .lista ul li ul.cat-container {
    /*                float: left;*/
}

.pagina-ficha-producto .contenedorDetalle .lista ul li a:last-child {
    /*color: #F94343;*/
}



/*.pagina-ficha-producto .contenedorDetalle h3 {
        display: none;
        margin-bottom: 10px;
        padding: 10px 15px;
        background-color: #f5f5f5;
        border-radius: 3px;
        color: #333;
        display: inline-block;
        margin-bottom: 10px;
        padding: 10px 15px;
        background: none;
        border-radius: 0px;
        -webkit-border-image: -webkit-gradient(linear, left top, left bottom, from(#00abeb), to(#fff), color-stop(0.5, #fff), color-stop(0.5, #66cc00)) 21 30 30 21 repeat repeat;
        color: #002E6D;
        font-family: Montserrat, sans-serif;
        font-size: 18px;
        font-weight: 700;
        padding-left: 0;*/
/* border-image: linear-gradient(to right, #E9408F 29%, #66CDF5 65%); */
/*border-image-slice: 1;
        border-width: 2px;
        border-right: none;
        border-top: none;
        border-left: none;
        text-transform: uppercase;
        width: 100%;
        float: left;
    }*/

/*.pagina-ficha-producto .contenedorDetalle h3 span {
    margin-top: 5px;
    position: relative;
    top: 9px;
    background: no-repeat;
    padding: 0;
}*/

.pagina-ficha-producto .compartir a {
    color: #EB008B;
    margin-right: 9px;
    cursor: pointer;
    cursor: hand;
    font-family: Jura,sans-serif;
    text-transform: uppercase;
    font-size: 13px;
}

.pagina-ficha-producto #body-tabs {
    margin-top: 20px;
    background: #F4F9FB;
}

.responsive-tabs-container .tab-pane {
    font-weight: 300;
}

    .responsive-tabs-container .tab-pane a.showCompartir {
        font-family: Jura,sans-serif;
        font-weight: 800;
        font-size: 13px;
        color: #EB008B;
        margin-bottom: 18px;
        position: relative;
        float: left;
        width: 100%;
    }

.tab-pane ul li a {
    font-family: Montserrat,sans-serif;
    font-size: 14px;
    padding-bottom: 0px;
    font-weight: 400;
}


.pagina-ficha-producto .tab-pane .nav-tabs {
    border-bottom: none;
    margin-bottom: 10px;
    text-transform: uppercase;
}

.tab-pane ul li.active a {
    color: #F94343;
    border-bottom: 1px solid #000;
    /*padding:0;*/
    margin-left: 15px;
    margin-right: 15px;
    padding: 0;
}

.tab-pane ul li:first-child.active a {
    padding: 0;
}
/*RESULTADOS DE BUSQUEDA*/
/*BUSQUEDAS*/
.resultados .subtitle h2, .resultados .palabraBusqueda {
    font-weight: 400;
    font-family: Lato,sans-serif;
    color: #616161;
    font-size: 26px;
    text-transform: uppercase;
}

.resultados .palabraBusqueda {
    color: #064E8A;
}

/*
.pagina-resultados .cont-subheader {
    background: #FFF;
    padding-bottom: 0px;
    margin-bottom: 0px;
}*/

/*.pagina-resultados .type-view {
    font-size: 23px;
    margin-top: -15px;
    margin-bottom: 0px;
}

    .pagina-resultados .type-view a.active {
        color: #951C1F;
    }

.pagina-resultados h2.resultados span.cantidadResultados {
    font-weight: bold;
}

.listado-resultados {
    padding-left: 15px;
}

    .listado-resultados > .row {
        border-bottom: 1px solid #CCCCCC;
    }

.pagina-resultados .listado .listado-resultados article.box .tapa {
    padding-left: 0px;
}

.pagina-resultados .listado .listado-resultados article.box .info .md-datos ul {
    list-style: none;
    padding-left: 0px;
}

.pagina-resultados .mosaico .listado-resultados article.box a img {*/
/*    max-height: 338px;
    height: 338px;*/
/*}

.pagina-resultados .cont-izquierda {
    padding-right: 0;
}

    .pagina-resultados .cont-izquierda h5 {
        color: #06326B;
        font-size: 18px;
        font-weight: 600;
        font-family: Montserrat,sans-serif;
        padding-left: 15px;
    }

    .pagina-resultados .cont-izquierda .filtros.filtrosTema {
        margin-bottom: 14px;
    }

    .pagina-resultados .cont-izquierda .filtros {
        padding: 0;
    }

        .pagina-resultados .cont-izquierda .filtros nav {
            padding: 14px;
            margin-bottom: 10px;
            float: left;
            width: 240px;
        }*/

.filtros a.catSuperior::before {
    padding-right: 5px;
    content: "\f100";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
}

/*.pagina-resultados .cont-izquierda .filtros h4 {
    font-family: Oswald;
    font-weight: 400;
    font-size: 12px;
    color: #666666;
}

.pagina-resultados .cont-izquierda .filtros h5 {
    font-family: Montserrat,sans-serif;
    font-size: 16px;
}

.cont-izquierda .border {
    border-top: 1px solid #000 !important;
}

.pagina-resultados .cont-central {*/
/* margin-top: 33px; */
/*}

    .pagina-resultados .cont-central .busqueda {*/
/* margin-top: 33px; */
/*border-left: 1px solid #A7A9B4;
    }*/

.busqueda .border-bottom {
    border-bottom: 1px solid #000 !important;
}

.busqueda .contenedorOrdenamiento {
    margin-right: -15px;
}

/*.filtros_aplicados .activo {
    background: url(/images/filtro-activo.png) #E4E4E4 no-repeat right 10px center;
    margin-left: -5px;
    padding-left: 5px;
    margin-bottom: 10px;
    color: #333333;
    font-size: 15px;
    width: 100%;
    float: left;
}*/

.cont-izquierda .filtros h6 {
    /*    color: #002E6D;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 16px;
    cursor: pointer;
    cursor: hand;
    padding-bottom: 11px;
    width: 100%;
    margin-top: 0px;
    margin-left: 0;
    font-family: Montserrat,sans-serif;
    margin-bottom: -4px;*/
}

.cont-izquierda .filtros ul {
    list-style: none;
}

    .cont-izquierda .filtros ul li.filtroOculto {
        display: none;
    }

    .cont-izquierda .filtros ul li a {
        /*   font-family: Montserrat,sans-serif;
        font-weight: 400;
        font-size: 16px;
        color: #A7A9B4;
        margin-left: 0px;
        display: inline-block;*/
    }

        .cont-izquierda .filtros ul li a.mas, .cont-izquierda .filtros ul li a.less {
            color: #DE7083;
        }

        .cont-izquierda .filtros ul li a i {
            color: #DE7083;
        }

/*.cont-izquierda .filtros h6.collapsable.collapsed:after {
    content: "\f067";
}

.cont-izquierda .filtros h6.collapsable:after {
    content: "\f068";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    font-size: 22px;
    color: #DE7083;
    float: right;
}
*/
/*.pagina-resultados .listado .listado-resultados article.box .md-compra {
    padding-right: 0px;
}

.pagina-resultados .listado .listado-resultados article.box figure {
    border-bottom: 1px solid #eaeaea;
    padding-bottom: 25px;
    padding-top: 25px;
}

.pagina-resultados .mosaico .listado-resultados article.box, .pagina-colecciones .mosaico .listado-resultados article.box {
    margin-top: 0px;
    margin-bottom: 15px;
}

    .pagina-resultados .mosaico .listado-resultados article.box figure a.img-container {*/
/*        display: inline-block;
        height: 346px;*/
/*}

    .pagina-resultados .mosaico .listado-resultados article.box figure figcaption h4, .pagina-colecciones .mosaico .listado-resultados article.box figure figcaption h4 {*/
/*        margin-bottom: 18px;
        height: 50px;
        margin-top: 5px;*/
/*}

        .pagina-resultados .mosaico .listado-resultados article.box figure figcaption h4 a, .pagina-colecciones .mosaico .listado-resultados article.box figure figcaption h4 a {
        }

    .pagina-resultados .mosaico .listado-resultados article.box figure .autor a, .pagina-colecciones .mosaico .listado-resultados article.box figure .autor a {
    }*/


/*.pagina-resultados .mosaico .listado-resultados .boton, .pagina-colecciones .mosaico .listado-resultados .boton {
    float: left;
    width: 100%;
    text-align: center;
}

.pagina-resultados .listado-resultados .formato, .vidriera figcaption .formato {
    font-family: Oswald,sans-serif;
    font-weight: 400;
    font-size: 14px;
    text-transform: uppercase;
    float: right;
}

.pagina-resultados .mosaico .listado-resultados article.box figure figcaption .autor, .pagina-colecciones .mosaico .listado-resultados article.box figure figcaption .autor {
    height: 40px;*/
/* font-size: 11.41px; */
/*overflow: hidden;
    text-align: center !important;
}*/

.cont-footer .border {
    border-top: 1px solid #000;
}

/*.listado-resultados a.consultar {
    margin-top: 10px;
    background: none;
    border: 0px solid;
    text-shadow: unset;
    box-shadow: unset;*/
/* font-size: 20px; */
/*margin-top: -8px;
    margin-left: 0px;
    color: inherit;
    margin-top: 6px;
    border: none;
    outline: none;
}

    .listado-resultados a.consultar::after {
        content: none;
    }

.listado-resultados {
    padding-left: 0px;
    margin-right: -15px;
}


    .listado-resultados > div {
        height: 580px;
    }

    .listado-resultados .boton {
        margin-top: -6px;
    }*/



/*.pagina-resultados .col-12.busqueda {
    padding-left: 0;
    padding-right: 0;
}
*/

.pagina-resultados .busqueda .palabraBusqueda {
}

.filtros, .filtros h6 {
}

    .filtros h6 {
        /*        padding-top: 5px;
        margin-left: 7px;*/
    }

    .filtros .dropdown {
        float: left;
        position: relative;
    }

.ordenamiento .filtros .dropdown {
    float: left;
    position: relative;
    border: 1px solid #002E6D;
    padding-top: 6px;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 10px;
}

.filtros .dropdown > a {
    float: left;
}

.ordenamiento .dropdown-menu.show {
    display: inherit;
    width: 100%;
    position: absolute;
    background: white;
    border: inherit;
    left: inherit;
    float: inherit;
    height: auto;
    position: absolute;
    left: inherit;
    right: inherit;
    margin-left: inherit;
    width: auto;
    margin: inherit;
}

.filtros .dropdown > a i {
    float: left;
}

.filtros .dropdown > a h6 {
    float: left;
    margin-left: 0;
    padding-top: 0px;
    cursor: pointer;
    color: #06326B;
    cursor: hand;
}

.filtros .dropdown a i {
    color: #333333;
    cursor: hand;
    margin-left: 6px;
    cursor: pointer;
}

    .filtros .dropdown a i.fa-sort-down {
        margin-left: -10px;
    }

.pagina-resultados .boton .comprar {
    /*background: url(/images/addToCart.png) no-repeat;
    height: 24px;
    width: 30px;
    position: relative;
    margin-left: 15px;
    display: block;*/
}
/*
.pagina-resultados .precio a {
    margin-top: 6px;*/
/* top: 10px; */
/*font-size: 12px;*/
/* padding-top: 10px; */
/*font-weight: 600;
    float: left;
    color: #333333
}*/

/*.pagina-resultados .consultar {
    margin-top: 6px;
}
*/
.modal-header .close {
    margin: 0;
}



/*Pagina Carrito*/

.progress-bar-success {
    background-color: #951C1F;
}

/*.pagina-carrito .cont-central .btn {
    color: #EF33A2;
    float: right;
    background: #DE7083;
    background-position: center;
    background-size: cover;
    height: 56px;
    font-family: Montserrat,sans-serif;
    border: none;
    width: auto;
    margin-left: 5px;
    padding-top: 17px;
    color: #fff;
}

    .pagina-carrito .cont-central .btn:hover {
        color: #DE7083;
        background: none;
        border: 1px solid #DE7083
    }*/

/*.pagina-carrito .totales .botones-group .btn.btn-success {
    color: #fff;
    float: right;*/
/* background-image: url(/images/btn.svg); */
/*background-position: center;
    background-size: cover;
    height: 56px;
    font-family: Montserrat,sans-serif;
    border: none;
    width: auto;
    margin-left: 5px;
    padding-top: 17px;
}

    .pagina-carrito .totales .botones-group .btn.btn-success:hover {
        background: none;
        border: 1px solid #DE7083;
        color: #DE7083;
    }*/
/*
.pagina-carrito .cont-central .btn.btn-continuar-compra {
}

    .pagina-carrito .cont-central .btn.btn-continuar-compra:hover {
        border: 1px solid #000;
    }

.pagina-carrito .cont-central .btn i {
    margin-right: 5px;
}*/

.pagina-carrito h3.title small {
    /*    font-size: 14px;
    color: #FF9999;
    font-family: Roboto,sans-serif;*/
}


.pagina-carrito .listado .header-row .headlabel {
    /*    font-size: 16px;
    font-weight: 500;
    padding: 12px;
    margin-bottom: 0px;
    COLOR: #000;
    TEXT-TRANSFORM: uppercase;
    font-family: Montserrat,sans-serif;*/
}

.pagina-carrito .precio {
    /*    font-size: 22px;*/
}

.pagina-carrito .totales .item .moneda {
    /*    font-size: 18px;*/
}


.pagina-carrito .listado .item {
}

    .pagina-carrito .listado .item .btn-delete {
        /* color: #666666;
        font-size: 12PX;*/
        /* padding: 3px 15px*/
    }

    .pagina-carrito .listado .item p {
        /*        margin: 0 0 4px;*/
    }

    .pagina-carrito .listado .item select {
    }




.pagina-carrito .headlabel {
    /*    font-size: 17px;*/
}

.pagina-carrito .listado .item h4 a {
    /*color: #000;
    font-size: 16px;
    text-transform: uppercase;*/
}

.pagina-carrito .listado .item .autores a {
    /*font-size: 13px;
    text-transform: uppercase;*/
}
/*
.pagina-carrito .totales .item {
    font-size: 22px;
    border-bottom: 3px solid #000;
    padding-top: 20px;
    padding-bottom: 20px;
}

    .pagina-carrito .totales .item .promo-destacado {
        text-transform: uppercase;
        font-size: 22px;
        font-weight: 400;
        font-family: Montserrat,sans-serif;
    }

        .pagina-carrito .totales .item .promo-destacado small {
            text-transform: uppercase;
            color: #A7A9B4;
            font-size: 12px;
            font-weight: 300;
            width: 61%;
            font-family: Montserrat,sans-serif;
            float: left;
        }

    .pagina-carrito .totales .item.total {
        border-bottom: none;
    }



.pagina-carrito .totales .botones-group {
    margin-top: 10px;
}

    .pagina-carrito .totales .botones-group a.btn-continuar-compra {
        color: #06326B;
        padding: 6px;
        font-family: Montserrat,sans-serif;
        padding-top: 19px;
    }

        .pagina-carrito .totales .botones-group a.btn-continuar-compra:hover {
            color: #F94343;
        }

.pagina-carrito .info {
    margin-top: 35px;
    padding: 10px;
    margin-bottom: 10px;
    text-align: center;
}

    .pagina-carrito .info h3 {
        font-size: 60px;
        font-family: 'Limelight',cursive;
    }

    .pagina-carrito .info p {
        font-size: 12px;
    }

    .pagina-carrito .info .border {
        border: 4px dotted #CACACA;
        padding: 11px;2512
    }

.pagina-carrito .totales .item .destacado .precio {
    font-size: 22px;
}

.pagina-carrito .info .item {
    padding-bottom: 15px;
    padding-top: 15px;
}
*/



.pagina-carrito .botones-group .btn {
}

.pagina-carrito .btn-success:hover .fa, .pagina-carrito .btn-success .fa:hover {
}

.pagina-carrito .info .item span {
    /*    float: left;*/
}

    .pagina-carrito .info .item span.left {
        /*        width: 12%;*/
    }

    .pagina-carrito .info .item span.right {
        /*width: 86%;
        padding-left: 2%;*/
    }

.pagina-carrito .info .fa {
    /*color: #F93E4C;
    font-size: 40px;
    display: inline-block;*/
}

.pagina-carrito .cupon-container .input-group {
    /*    padding: 7px;
    border: 1px solid #000;
    margin-left: 0px;
    width: 95%;*/
}

    .pagina-carrito .cupon-container .input-group .input-group-addon {
        /*border: none;*/
        /* padding-left: 0; */
        /* color: #333333; */
        /*font-size: 24px;*/
        /* padding: 0px 10px 0 0; */
        /*width: 100%;
        display: table-row;
        text-align: left;
        padding: 0px;
        background: no-repeat;*/
        /* color: #66CCFF; */
        /*text-transform: uppercase;*/
    }

        .pagina-carrito .cupon-container .input-group .input-group-addon span {
            /* margin-top: -3px;
            position: relative;
            top: -4px;
            text-transform: uppercase;
            font-size: 22px;
            font-weight: 400;
            font-family: Montserrat,sans-serif;*/
        }

.pagina-carrito .cont-central .cupon-container .btn {
    /*height: 30px;
    margin-left: 5px;
    padding-top: 4px;*/
}


.pagina-carrito .cupon-container .input-group .input-group-btn {
    /*  padding-left: 6px;
    float: left;
    width: 32%;*/
}

.pagina-carrito .cupon-container .input-group .form-control {
    /*  border-radius: 0;
    HEIGHT: 40px;
    width: 63%;
    float: left;
    font-size: 16px;*/
}


    .pagina-carrito .cupon-container .input-group .form-control::-webkit-input-placeholder, .pagina-carrito .cupon-container .input-group .form-control::-webkit-input-placeholder {
    }




/*.nav-stacked li {
    list-style: none;
}*/
/*
.pagina-especial .cont-subheader {
    margin-top: 0;
}

.pagina-especial .menu ul.nav.nav-stacked li {
    width: 100%;
    display: inline-block;
    flex: auto;
    text-align: left;
}



.pagina-especial #contenedorCentral h2 {
    font-weight: 400;
    font-size: 20px;
}

.pagina-especial #contenedorCentral h4 {
    font-family: Oswald,sans-serif;
    font-weight: 300;
    font-size: 14px;
    color: #000;
}

.pagina-especial #contenedorCentral .menu .nav li a.active {
    cursor: pointer;
    cursor: hand;
}

.pagina-especial a:hover {
    color: #F94343;
}

.pagina-especial .form-container .embed-responsive {
    height: 497px;
}

.pagina-especial #contenedorCentral h3 {
    font-family: Oswald,sans-serif;
    font-weight: 300;
    font-size: 16px;
    color: #000000;
}
*/


/*pagina categorias catalogo*/

.arbolCategorias.filtros nav ul li a, .arbolCategorias.filtros nav ul li span {
    font-weight: 700;
}

.arbolCategorias.filtros nav ul li:hover a, .arbolCategorias.filtros nav ul li:hover span, .arbolCategorias.filtros nav ul li:hover .fa {
    color: #E20613;
    text-decoration: none;
}

.arbolCategorias.filtros nav ul li span {
    font-size: 14px;
    color: #000;
}

.arbolCategorias.filtros nav ul li.parent span, .arbolCategorias.filtros nav ul li.parent .fa {
    color: #E20613;
}

.arbolCategorias.filtros nav ul li.parent .fa-plus {
    display: none;
    cursor: pointer;
}

.arbolCategorias.filtros nav ul li.parent.collapsed .fa-plus {
    display: inline-block;
    cursor: pointer;
}

.arbolCategorias.filtros nav ul li.parent.collapsed .fa-minus {
    display: none;
    cursor: pointer;
}

.arbolCategorias.filtros nav ul li.parent i {
    float: right;
    cursor: pointer;
    margin-top: 6px;
}

.arbolCategorias.filtros nav ul {
    padding: 0;
}

.arbolCategorias.filtros ul.subcategorias {
    padding-left: 0px;
    font-size: 14px;
    font-weight: 300;
    clear: left;
    padding-left: 15px;
}

    .arbolCategorias.filtros ul.subcategorias li.subparent {
        clear: both;
    }

        .arbolCategorias.filtros ul.subcategorias li.subparent a {
            font-family: Oswald,sans-serif;
            font-weight: 400;
            font-size: 18px;
            color: #F94343;
            clear: both;
        }

        .arbolCategorias.filtros ul.subcategorias li.subparent .subsubcategorias {
            float: left;
            cursor: pointer;
            cursor: hand;
            clear: left;
        }

        .arbolCategorias.filtros ul.subcategorias li.subparent ul.subsubcategorias {
            padding-left: 20px;
        }

            .arbolCategorias.filtros ul.subcategorias li.subparent ul.subsubcategorias a {
                color: #000000;
                text-transform: none;
                font-size: 14px;
            }

.arbolCategorias.filtros .subsubcategorias.collapsed .fa-minus {
    display: none;
}

.arbolCategorias.filtros .subsubcategorias.collapsed .fa-plus {
    display: inline;
}

.arbolCategorias.filtros .subsubcategorias .fa-minus {
    display: inline;
}

.arbolCategorias.filtros .subsubcategorias .fa-plus {
    display: none;
}

.arbolCategorias.filtros ul.subcategorias li a, .arbolCategorias.filtros ul.subcategorias li span {
    font-weight: 300;
    text-transform: uppercase;
    color: #000;
    font-size: 14px;
    padding: 4px 0;
    display: block;
    font-family: Roboto,sans-serif;
    font-weight: 400;
    -webkit-transition: color 0.25s ease 0s;
    -moz-transition: color 0.25s ease 0s;
    transition: color 0.25s ease 0s;
}

.arbolCategorias .mas, .arbolCategorias .more {
    clear: both;
}

.arbolCategorias.filtros {
    margin-top: 14px;
}

/*LOCALES*/
.pagina-listado-locales .cont-subheader {
    margin-top: 0;
}

.pagina-listado-locales .slick-slide img {
    max-height: 237px;
}

.carousel {
    position: relative;
}

.carousel-inner {
    overflow: hidden;
    position: relative;
    width: 100%;
}

    .carousel-inner > .item {
        display: none;
        position: relative;
        transition: left 0.6s ease-in-out 0s;
    }

        .carousel-inner > .item > img, .carousel-inner > .item > a > img {
            line-height: 1;
        }

    .carousel-inner > .active, .carousel-inner > .next, .carousel-inner > .prev {
        display: block;
    }

    .carousel-inner > .active {
        left: 0;
    }

    .carousel-inner > .next, .carousel-inner > .prev {
        position: absolute;
        top: 0;
        width: 100%;
    }

    .carousel-inner > .next {
        left: 100%;
    }

    .carousel-inner > .prev {
        left: -100%;
    }

        .carousel-inner > .next.left, .carousel-inner > .prev.right {
            left: 0;
        }

    .carousel-inner > .active.left {
        left: -100%;
    }

    .carousel-inner > .active.right {
        left: 100%;
    }

.carousel-control {
    bottom: 0;
    color: #fff;
    font-size: 20px;
    left: 0;
    opacity: 0.83;
    position: absolute;
    text-align: center;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
    top: 0;
    width: 15%;
}

    .carousel-control.left {
        background: rgba(0, 0, 0, 0) url("/userControls/carousel/images/hori_large.png") no-repeat scroll 0 0;
        height: 30px;
        margin-top: 35%;
        width: 30px;
    }

    .carousel-control.right {
        background: rgba(0, 0, 0, 0) url("/userControls/carousel/images/hori_large.png") no-repeat scroll 2px -31px;
        height: 30px;
        left: auto;
        margin-top: 35%;
        right: 0;
        width: 30px;
    }

    .carousel-control:hover, .carousel-control:focus {
        color: #fff;
        opacity: 0.9;
        outline: 0 none;
        text-decoration: none;
    }

    .carousel-control .icon-prev, .carousel-control .icon-next, .carousel-control .glyphicon-chevron-left, .carousel-control .glyphicon-chevron-right {
        display: inline-block;
        position: absolute;
        top: 50%;
        z-index: 5;
    }

    .carousel-control .icon-prev, .carousel-control .glyphicon-chevron-left {
        left: 50%;
    }

    .carousel-control .icon-next, .carousel-control .glyphicon-chevron-right {
        right: 50%;
    }

    .carousel-control .icon-prev, .carousel-control .icon-next {
        font-family: serif;
        height: 20px;
        margin-left: -10px;
        margin-top: -10px;
        width: 20px;
    }

        .carousel-control .icon-prev::before {
            content: "�";
        }

        .carousel-control .icon-next::before {
            content: "�";
        }

.carousel-indicators {
    bottom: 10px;
    left: 50%;
    list-style: outside none none;
    margin-left: -30%;
    padding-left: 0;
    position: absolute;
    text-align: center;
    width: 60%;
    z-index: 15;
}

    .carousel-indicators li {
        background-color: rgba(0, 0, 0, 0);
        border: 1px solid #fff;
        border-radius: 10px;
        cursor: pointer;
        display: inline-block;
        height: 10px;
        margin: 1px;
        text-indent: -999px;
        width: 10px;
    }

    .carousel-indicators .active {
        background-color: #fff;
        height: 12px;
        margin: 0;
        width: 12px;
    }

.carousel-caption {
    bottom: 20px;
    color: #fff;
    left: 15%;
    padding-bottom: 20px;
    padding-top: 20px;
    position: absolute;
    right: 15%;
    text-align: center;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
    z-index: 10;
}

    .carousel-caption .btn {
        text-shadow: none;
    }

.resultado-enriquecido {
    margin-bottom: 40px;
}

.pagina-nota-detalle .compartir {
    margin-left: -7px;
    margin-bottom: 40px;
}

    .pagina-nota-detalle .compartir a {
        color: #000000;
        margin-right: 9px;
        cursor: pointer;
        cursor: hand;
    }

.contenedorEditoriales {
    margin-top: 20px;
}

    .contenedorEditoriales .slick-prev, .contenedorEditoriales .slick-next {
        display: none !important;
    }

    .contenedorEditoriales h3 {
        font-family: Montserrat,sans-serif;
        font-weight: 700;
        font-size: 32px;
        color: #4B5253;
    }

    .contenedorEditoriales span.bajadaEditoriales {
        font-family: Montserrat,sans-serif;
        font-size: 16px;
        font-weight: 400;
        color: #4B5253;
    }

.responsive-tabs-container .tab-pane a.btn {
    /* border-radius: 69px;
    */
    /* height: 115px; */
    width: 115px;
    padding: 27px;
    /* background: #F799D1; */
    /* border: 6px solid #fff; */
    BORDER: none;
}


/*.pagina-resultados .container .banner .border {
    border: none;
}

.pagina-resultados .container .banner h4 {
    color: #fff;
    font-family: Montserrat,sans-serif;
    font-weight: 700;
    font-size: 32px;
    text-align: center;
    width: 100%;
}

.pagina-resultados .container .banner h5 {
    color: #fff;
    font-family: Montserrat,sans-serif;
    font-weight: 400;
    font-size: 16px;
    text-align: center;
}

.pagina-resultados .container .banner .lnkVerMas {
    display: none !important;
}

.pagina-resultados .container .banner .info {
    width: 50%;
    margin: 0 auto;
    margin-top: -370px;*/
/*background: linear-gradient(90deg, #A593BF, #E6E2DF 106%);*/
/*padding-bottom: 10px;
    padding-left: 50px;
    padding-right: 50px;
}*/




/*Mobile*/
@media (max-width: 767px) {

    .mensajeNoDisponible.fucsia {
        text-align: center !important;
        width: 100%;
        margin: 0 auto;
    }
    /*
    .pagina-resultados .mosaico .listado-resultados article.box figure a.img-container {
        height: auto;
    }

    .pagina-resultados .mosaico .listado-resultados article.box figure figcaption .autor, .pagina-colecciones .mosaico .listado-resultados article.box figure figcaption .autor {
        height: auto;
    }*/

    /*    .listado-resultados > div {
        height: auto;
        margin-bottom: 20px;
    }*/

    /* .pagina-resultados .container .banner h5 {
        display: none;
    }

    .pagina-resultados .container .banner .info {
        width: 90%;
        float: none;
        position: relative;
        display: flex;
    }

    .pagina-resultados .mosaico .listado-resultados article.box figure figcaption h4 a, .pagina-colecciones .mosaico .listado-resultados article.box figure figcaption h4 a {
        min-height: auto;
    }

    .pagina-resultados .mosaico .listado-resultados article.box figure a {
        height: auto;
        min-height: auto;
    }*/

    .vidriera.ofertas {
        margin-top: 15px;
    }

    /*.pagina-resultados .mosaico .listado-resultados article.box figure a.btn.comprar {
        height: 35px;
    }*/
    /*RESULTADOS DE BUSQUEDA*/
    /*.pagina-resultados .cont-izquierda {
        padding-right: 15px;
    }

    .pagina-resultados .filtrosMovil a.btn {
        color: #06326B;
        border: 2px solid #06326B;
    }

        .pagina-resultados .filtrosMovil a.btn:after {
            content: "\f078";
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            font-size: 22px;
            color: #333333;
            float: right;
        }

    .pagina-resultados .cont-izquierda .filtros nav {
        width: 100%;
    }

    .pagina-resultados .filtrosMovil a[aria-expanded=true]:after {
        content: "\f077";
    }*/


    .responsive-tabs-container .tab-pane a.btn {
        /* border-radius: 69px;
        height: 84px;*/
        width: 84px;
        padding: 13px;
    }

    .fichaPrincipal .info-adicional .verticalBorder {
        border-right: none;
    }

    .pagina-ficha-producto .mainDetalleContainer {
        /* background: linear-gradient(180deg, #FBCCE8, #FCFCFC 68%);*/
    }


    .copyright {
        font-size: 12px;
    }

    .contenedorSuscripcion {
        /*
        background: #FBCCE8;*/
    }

    /*.cont-footer aside.mail-novedades h4 {
        font-size: 22px;
    }
*/
    #contendorFooter {
        margin-top: 10px;
    }

    .contenedorEditoriales h3 {
        text-align: center;
    }

    /*.contenedorSuscripcion a.btn {
        width: 100%;
        border: 1px solid #EB008B;
    }*/

    .cont-footer aside.mail-novedades p {
        text-align: left;
    }

    .contenedorEditoriales span.bajadaEditoriales {
        text-align: center;
    }

    .contenedorEditoriales .slick-dotted.slick-slider {
        margin-bottom: 0;
    }

    .contenedorEditoriales .slick-dots {
        display: none;
    }

    .masVendidos .header-vidriera img {
        float: left;
        margin-right: 3%;
        margin-left: 0%;
        width: 32px;
    }

    .masVendidos .vidriera h3.title {
        padding-bottom: 0;
    }

        .masVendidos .vidriera h3.title a {
            font-size: 20px;
        }

    .masVendidos span.orden {
        font-size: 67px;
    }

    .masVendidos ul li:nth-child(n+4) {
        display: none;
    }

    .vidriera article.recomendado .tapa {
        width: 100%;
    }

    .vidriera article.recomendado figcaption {
        width: 100%;
    }

    .vidriera article.recomendado .resenia {
        display: none;
    }

    .widgetHomeContenedor {
        padding-left: 0;
        padding-right: 0;
        margin-left: -15px;
        margin-right: -15px;
        background: none;
    }

        .widgetHomeContenedor h5 span {
            height: auto;
        }

        .widgetHomeContenedor div.info {
            background: white;
            margin-left: 0%;
            margin-right: 0%;
            margin-top: 0;
            padding: 13px;
            float: left;
        }

        .widgetHomeContenedor .border {
            position: relative;
            background: linear-gradient(to right, #FBCCE8, #DBDCDD);
            padding: 4px;
            float: left;
            margin-top: -63px;
        }

    .beneficiosHome {
        padding: 0;
        text-align: center;
    }

        .beneficiosHome h2 {
            text-align: center;
            font-size: 26px;
        }



    header .navbar-default {
        /*background: #F7E5F3;*/
    }

    header ul.contenedorMiCuenta {
        /* background:#fff;*/
    }


    /*    a.navbar-brand {
        float: right;
        margin-right: 9%;
    }*/

    /* .navbar-toggler {
        margin-left: 28%;
    }
*/




    .mensajePromo {
        font-size: 9px;
    }


    .pagina-carrito .headlabel {
        /*        font-size: 14px;*/
    }


    .pagina-carrito .precio {
        /*        font-size: 14px;*/
    }


    .pagina-carrito .listado .item select + i.fa {
        /*float: left;
        margin-top: -29px;
        margin-left: 59px;
        pointer-events: none;
        background: no-repeat;
        padding-right: 5px;*/
        /* color: #064E8A; */
        /*z-index: 1000;
        position: relative;*/
    }

    .pagina-carrito .listado .item .btn-delete {
        /*color: #666666;
        font-size: 12PX;*/
        /* padding: 3px 15px; */
        /*margin-left: 15px;*/
    }


    .resultado-enriquecido h3.title {
        margin-top: 25px;
    }


    #menuFooter a.redesSociales {
        padding-top: 3px;
    }

    .pagina-formulario .formulario {
        margin-top: 10px;
    }

    /*.buscadorMobile {
        height: 75px;
    }*/
    /*carrito*/
    .pagina-carrito .cont-central .btn {
        /*        font-size: 15px;*/
    }

    .pagina-carrito h1 {
        /*        margin-top: 15px;*/
    }
    /*popup carrito*/
    /*    #modalCarrito a.btn-success {
        margin-left: 0;
    }*/
    /*busquedas*/
    .popup-filtros ul {
        list-style: none;
    }

    .popup-filtros .filtros h6 {
        margin-left: 0;
        text-transform: uppercase;
    }

    /*    .pagina-resultados .mosaico .listado-resultados article.box figure figcaption h4, .pagina-colecciones .mosaico .listado-resultados article.box figure figcaption h4 {
        height: auto;
    }
*/
    #lnkOrdenar {
        /*float: left;
        margin-top: 11px;
        text-align: center;
        margin: 0 auto;
        padding-left: 24%;*/
    }

    /*    .pagina-resultados .cont-izquierda .filtros {
        padding: 0;
        width: 100%;
    }

    .pagina-resultados .cont-central {
        border-left: none;
    }

        .pagina-resultados .cont-central .busqueda {
            border-left: none;
        }*/

    /*  .list-filtros .fa-filter {
        font-size: 16px;
    }

    #lnkOrdenar span {
        margin-right: 5px;
    }

    #lnkOrdenar span, #lnkOrdenar i, #lnkOrdenar .ordenActual {
        float: left;
    }

        #lnkOrdenar i.fa-sort-down {
            margin-left: -14px;
            margin-right: 10px;
        }

    .busqueda .border-bottom {
        border-bottom: none !important;
    }*/
    /*FICHA*/
    /*    .pagina-ficha-producto h1 {
        font-size: 26px;
     
    }*/

    /*.responsive-tabs-container .accordion-link {
        display: none;
        margin-bottom: 10px;
        padding: 10px 15px;
        background: none;
        border-radius: 0px;
        -webkit-border-image: -webkit-gradient(linear, left top, left bottom, from(#00abeb), to(#fff), color-stop(0.5, #fff), color-stop(0.5, #66cc00)) 21 30 30 21 repeat repeat;*/
    /* border: none; */
    /*color: #002E6D;*/
    /* border-bottom: 1px solid #000; */
    /*font-family: Montserrat, sans-serif;
        font-size: 18px;
        font-weight: 600;
        padding-left: 0;*/
    /* background: linear-gradient(to right, #E9408F 0%, #66CEF5 100%); */
    /* text-transform: uppercase; */
    /* background-attachment: unset; */
    /* background-blend-mode: color; */
    /* background-clip: border-box; */
    /* border-image: linear-gradient(to right, #E9408F 29%, #66CDF5 65%); */
    /* border-image-slice: 1; */
    /* border-width: 2px; */
    /* background-position-y: unset; */
    /* background-repeat-y: inherit; */
    /* background-size: unset; */
    /*border-right: none;
        border-top: none;
        border-left: none;
        text-transform: uppercase;
        border: none;
    }*/

    .responsive-tabs-container.accordion-xs .accordion-link:after {
        /*
        content: "\f067";
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        float: right;
        color: #DE7083;*/
    }

    .responsive-tabs-container.accordion-xs .accordion-link.active:after {
        /*content: "\f068";
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        float: right;
        color: #FF0099;*/
    }

    .navbar-default .header {
        background: none;
    }
    /*
    #navigationxs {
        margin-bottom: 30px;
    }*/

    /*#navigationxs ul {
        display: flex;
        flex-direction: column;
    }*/

/*    .navbar-default ul li.header-title ul li a {
        margin-top: -20px;
    }

    .navbar-default ul li.header-title a {
        margin-bottom: 0;
    }

    #navigationxs ul li ul li {
        padding-top: 0;
        padding-bottom: 0;
    }

    #navigationxs ul li.banner {
        display: none;
    }
*/
    /*.dropdown-menu.show {
        position: relative !important;
        display: contents;
    }
*/
    .navbar-default ul li.header-title ul li a {
        margin-left: 30px;
        font-size: 13px;
    }

    .dropdown-toggle::after {
        display: inline-table;
        width: 30px;
        height: 0;
        float: right;
        background: #fff;
        padding: 2px;
        margin-left: .255em;
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        vertical-align: .255em;
        content: "\f107";
        border-top: .3em solid;
        border-right: .3em solid transparent;
        border-bottom: 0;
        border: none;
        border-left: .3em solid transparent;
        margin-right: 6%;
        border-radius: 24px;
        padding-left: 8px;
    }

    /*.fa, .fas, .fab {
        font-size: 23px;
    }
*/
    .carrito .fas {
        color: #F94343;
    }

    .navbar-default ul.contenedorMiCuenta li a {
        padding: 0px;
    }

    .navbar-default .h-100 {
        height: auto !important;
    }

    /*.navbar-default ul.contenedorMiCuenta li a.navbar-brand {
        float: right;
    }*/


/*    .pagina-especial .cont-subheader {
        margin-top: 0;
    }*/



    /*banners*/

    /*SUBFOOTER*/
    /*#menuFooter ul {
        margin-bottom: 23px;
    }*/

    /*blog*/

    .pagina-nota-detalle h1 {
        margin-top: 10px;
    }


    .pagina-notas .notas-listado article {
        border-left: 0;
    }
}

@media (max-width: 640px) {
}

@media (max-width: 480px) {
}

@media (max-width: 360px) {
}

@media (max-width: 320px) {
}
/*Fin Mobile*/
@media (min-width: 768px) {

    .responsive-tabs-container .tab-pane a.btn {
        border-radius: 69px;
        height: 115px;
        width: 115px;
        padding: 27px;
        background: #F799D1;
        border: 6px solid #fff;
        border-style: double;
    }

    .responsive-tabs-container .tab-pane a[aria-expanded="true"] {
        background: #66CEF5;
    }

    .navbar-default .header {
    }

    .fa, .fas {
        font-size: 23px;
    }

    .contenedorMiCuenta {
        font-size: 18px;
        font-family: Jura,sans-serif;
        font-weight: 600;
    }

    .navbar-default ul.contenedorMiCuenta li a {
        font-size: 12px;
        font-weight: 600;
        padding: 6px;
        display: inline-block;
    }

        .navbar-default ul.contenedorMiCuenta li a span {
            margin-left: 0px;
            position: relative;
            top: 1px;
            color: #DE7083;
        }

    .navbar-default ul.contenedorMiCuenta li.menu {
        padding-right: 38px;
    }

    .navbar-default ul.contenedorMiCuenta .carrito .fas {
        color: #F94343;
    }

    #navigationxs {
        margin-bottom: 30px;
    }

        #navigationxs ul li {
            width: 100%;
        }

    /*ficha producto*/
    .pagina-ficha-producto .contenedorDetalle .lista ul li ul.cat-container {
        float: none;
        display: inline-block;
    }

    .pagina-ficha-producto .contenedorDetalle .lista ul li {
    }

        .pagina-ficha-producto .contenedorDetalle .lista ul li b {
            float: none;
        }
    /*
    a.navbar-brand img {
        max-width: 210px;
    }*/

    .pagina-carrito .precio {
        /*        font-size: 18px;*/
    }

    .pagina-carrito .totales .item .destacado .precio {
        font-size: 19px;
    }

    .navbar-default ul.contenedorMiCuenta li a.carrito {
        height: 32px;
        width: 31px;
        /* padding: 5px; */
        padding: 0px;
        top: 5px;
    }

    .navbar-default ul.contenedorMiCuenta li a.navbar-brand {
        float: right;
    }

    .slick-prev, .slick-next {
        display: none !important;
    }
    /*
    .pagina-resultados .container .banner .info {
        width: 75%;
        margin-top: -335px;
    }
*/
    .vidriera.ofertas {
        margin-top: 15px;
    }
    /*RESULTADOS DE BUSQUEDA*/
    /*.pagina-resultados .cont-izquierda {
        padding-right: 15px;
    }

    .pagina-resultados .filtrosMovil a.btn {
        color: #06326B;
        border: 2px solid #06326B;
    }

        .pagina-resultados .filtrosMovil a.btn:after {
            content: "\f078";
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            font-size: 22px;
            color: #333333;
            float: right;
        }

    .pagina-resultados .cont-izquierda .filtros nav {
        width: 100%;
    }

    .pagina-resultados .filtrosMovil a[aria-expanded=true]:after {
        content: "\f077";
    }*/



    /*#lnkOrdenar {
        float: left;
        margin-top: 11px;
        text-align: center;
        margin: 0 auto;
        padding-left: 35%;
        margin: auto;
        position: relative;
        display: inline-block;
        position: relative;
    }

        #lnkOrdenar .ordenActual {
            float: left;
            position: relative;
        }

        #lnkOrdenar > span {
            float: left;
            position: relative;
            margin-right: 11px;
        }*/
    /*

    .pagina-resultados .cont-izquierda .filtros {
        padding: 0;
        width: 100%;
    }
*/


    /*    .vidriera article h4 a, .listado-resultados article h4 a {
        font-size: 1.12rem;
    }

    .pagina-home .vidriera.bestsellers .title a {
        font-size: 6rem;
    }
*/
    .responsive-tabs-container.accordion-xs .accordion-link:after {
        /*  content: "\f067";
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        float: right;
        color: #FF0099;*/
    }

    .responsive-tabs-container.accordion-xs .accordion-link.active:after { /*
        content: "\f068";
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        float: right;
        color: #FF0099;*/
    }

    .pagina-ficha-producto .mainDetalleContainer {
        /* background: linear-gradient(180deg, #FBCCE8, #FCFCFC 68%);*/
    }

    /*    .contenedorSuscripcion {
        background: #FBCCE8;
    }*/
    /*
    .cont-footer aside.mail-novedades h4, #menuFooter .header-title > a {
        font-size: 18px;
        font-weight: 700;
    }*/

    /* #menuFooter .header-title > a {
        font-size: 15px;
    }*/

    /*.contenedorSuscripcion a.btn {
        width: 100%;
        border: 1px solid #EB008B;
    }*/

    .masVendidos .border {
        margin-left: 15%;
        margin-right: 15%;
    }

    .masVendidos ul li:nth-child(n+4) {
        display: none;
    }

    .widgetHomeContenedor {
        margin-bottom: 40px;
    }

        .widgetHomeContenedor div.info {
            margin-top: 18px;
            top: 0;
            position: relative;
            float: left;
        }

        .widgetHomeContenedor .border {
            border: 1px solid #dee2e6 !important;
            margin-top: -39px;
            position: relative;
            z-index: 3000;
            /* margin-bottom: 115px; */
            float: left;
        }

        .widgetHomeContenedor .slick-dotted {
            margin-bottom: 0;
            margin-top: -52px;
        }

        .widgetHomeContenedor .slick-dots {
        }

            .widgetHomeContenedor .slick-dots li button {
            }


    .beneficiosHome {
        padding: 0;
        text-align: center;
    }

        .beneficiosHome h2 {
            text-align: center;
            font-size: 26px;
        }

        .beneficiosHome .info h4 {
            float: left;
        }

    /*.buscador .form-inline {
        margin-top: 16px;
    }*/

    #navigationxs ul li {
        width: 114%;
        border-bottom: 2px solid #fff;
        float: left;
        margin-left: -15px;
        padding-top: 10px;
        margin-right: -15px;
        padding-bottom: 10px;
    }

    .navbar-default ul li.header-title ul li a {
        margin-top: -20px;
    }

    .navbar-default ul li.header-title a {
        margin-bottom: 0;
    }


   /* .dropdown-menu.show {
        position: relative !important;
        display: contents;
    }*/

    .navbar-default ul li.header-title ul li a {
        margin-left: 30px;
        font-size: 13px;
    }

    header {
        /*  background: #F7E5F3;*/
    }

    .navbar-default .buscador {
        /*        padding-left: 0;*/
        /* height: 92px !important;*/
    }

    /* header .contenedorRegistracion {
        background: #fff;
        margin-left: -30px;
        margin-right: -38px;
    }*/

    /*.dropdown-toggle::after {
        display: inline-table;
        width: 30px;
        height: 0;
        float: right;
        background: #fff;
        padding: 0px;
        margin-left: .255em;
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        vertical-align: .255em;
        content: "\f107";
        border-top: .3em solid;
        border-right: .3em solid transparent;
        border-bottom: 0;
        border: none;
        border-left: .3em solid transparent;
        margin-right: 6%;
        border-radius: 24px;
        padding-left: 4px;
    }*/


    #searchMobile {
        /*  margin-top:4px;*/
    }

    .navbar-toggler {
        color: #06326B;
    }

    .contenedorMiCuenta .menu div span {
        color: #EB008B;
        font-family: Jura,sans-serif;
        font-size: 12px;
    }


    /*.buscadorMobile {
        height: 75px;
    }*/
    /*resulados de busqueda*/
    .filtros .dropdown a i.fa-sort-down {
        margin-left: -14px;
    }

    /*FICHA*/
    .pagina-ficha-producto h1 {
        margin-top: 12px;
    }

    .banner {
        margin-bottom: 15px;
    }

    .formSuscripcion {
        height: 110px;
    }

    /*blog*/
    .pagina-notas .destacado-lectura .copete {
        max-height: 52px;
        overflow: hidden;
    }

    .pagina-notas .notas-listado article:nth-child(2) .separadorSimple {
        display: none;
    }

    .pagina-notas .notas-listado article:nth-child(2n+1) {
        border-left: none;
    }
    /*blog detalle*/
    .pagina-nota-detalle .lowCantity .slick-track {
        width: 720px !important;
    }
}

@media (min-width: 992px) {
    html {
        font-size: 16px;
    }
}

@media (min-width: 1200px) {
    .pagina-ficha-producto .mainDetalleContainer a.tapa img {
        min-height: 363px;
    }

    /*.navbar-brand {
        padding-left: 15px;
    }*/

    .pagina-ficha-producto .compartir a {
        font-size: 21px;
    }

    /*.contenedorDetalle a.btn.btn-primary {*/
    /*border-radius: 69px;
        */
    /* height: 115px; */
    /*width: 80px;*/
    /* padding: 27px; */
    /* background: #F799D1; */
    /* border: 6px solid #fff; */
    /*border: none;
    }*/

    /* .contenedorDetalle a.btn.btn-primary[aria-expanded="true"] {
            background: none;
        }*/


    .dropdown-menu.show {
        top: 35px;
    }

    .beneficiosHome h2 {
        width: 81%;
    }
    /*FICHA PRODUCTO*/
    .fichaPrincipal .contenedorPrecios {
        padding-top: 19px;
        padding-bottom: 10px;
    }

    /*blog*/
    .pagina-notas .destacado-lectura figcaption {
        padding: 9%;
    }

    .pagina-notas .destacado-lectura figure {
        padding: 3%;
    }

    .pagina-notas .notas-listado article:nth-child(2) .separadorSimple, .pagina-notas .notas-listado article:nth-child(3) .separadorSimple {
        display: none;
    }

    .pagina-notas .notas-listado article:nth-child(3n+1) {
        border-left: none;
    }


    /*blog detalle*/
    .pagina-nota-detalle .lowCantity .slick-track {
        width: 1370px !important;
    }

    .fa, .fas {
        font-size: 17px;
    }

    .contenedorRegistracion #navitagion {
        padding-left: 3% !important;
        padding-right: 4% !important;
    }

    /*#texttosearch {
        width: 517px;
    }
*/
    .container {
        max-width: 1360px;
    }
    /*suscripcion home*/
    .formSuscripcion {
        padding-right: 21%;
        padding-top: 2%;
    }


    .cont-subheader {
        overflow: hidden;
        border-bottom: 1px solid var(--primary-color);
    }



    .navbar-default ul.contenedorMiCuenta .carrito a {
        color: #F94343;
    }

    .navbar-default .header {
    }

    .contenedorMiCuenta {
        font-size: 14px;
        padding-right: 0% !important;
        /*   max-width:135px;*/
    }

        .contenedorMiCuenta li.contenedorCarrito {
            padding-right: 0;
        }

        .contenedorMiCuenta div {
            /* float: left; */
            position: relative;
            display: flex;
            /* width: 100%; */
            text-align: r;
            /* max-width: 132px; */
            /* margin-bottom: 10px;*/
        }

    .navbar-default ul.contenedorMiCuenta li a {
        font-size: 14px;
        padding: 0px;
        padding-right: 0;
        top: 3px;
        position: relative;
        -webkit-appearance: none;
    }

        .navbar-default ul.contenedorMiCuenta li a.dropdown-item {
            display: block;
            width: 100%;
            padding: .25rem 1.5rem;
            clear: both;
            text-align: inherit;
            white-space: nowrap;
            background-color: transparent;
            border: 0;
        }

        .navbar-default ul.contenedorMiCuenta li a:hover {
            cursor: hand;
            cursor: pointer;
        }

        .navbar-default ul.contenedorMiCuenta li a.carrito {
            /*    top: -4px;
            position: relative;*/
        }
}
